.container {
    background-color: rgba(var(--r), var(--g), var(--b), 0.1);
    transition: background-color 150ms;
    border-radius: var(--border-radius-large);
}

.container:hover {
    background-color: rgba(var(--r), var(--g), var(--b), 0.15);
    transition: background-color 0ms;
}

.container:active {
    background-color: rgba(var(--r), var(--g), var(--b), 0.2);
    transition: background-color 0ms;
}

.inner {
    padding: 16px;
}

.featuredText {
    font-size: 14px;
    font-family: monospace;
}

.title {
    color: var(--black);
    transition: color 200ms;
}
.title:hover {
    color: var(--blue);
}

.artist {
    color: var(--highDarkGrey) !important;
    text-decoration: none;
}
.artist:hover {
    text-decoration: underline;
}

.button {
    outline: none;
    border: none;
    border-radius: var(--border-radius-small);
    background-color: var(--black);
    color: var(--white);
    padding: 10px 28px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: opacity 150ms;
}
.button:hover {
    opacity: 0.85;
    transition: opacity 0ms;
}
.button:active {
    opacity: 0.70;
    transition: opacity 0ms;
}

.tag {
    font-size: 12px;
    color: var(--highDarkGrey);
    text-align: right;
    margin-top: 4px;
    margin-right: 8px;
}