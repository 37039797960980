.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.alignCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.numberContainer {
    display: flex;
    position: absolute;
    bottom: -8px; 
    min-width: 12px; 
    padding: 0px 6px;
    height: 24px; 
    border-radius: 1000px; 
    background-color: var(--darkGrey); 
    align-items: center; 
    justify-content: center;
    z-index: 10;
    transition: opacity 200ms;
}

.number {
    margin-left: -1px;
    text-align: center;
    font-size: 11px;
    color: var(--white);
}

:global(.logged-in) :global(.content-options-wrapper):hover .numberContainer {
    opacity: 0;
    transition: opacity 0ms;
}

.textContent {
    margin-top: 12px;
}

.title {
    font-size: 16px;
}

.title a {
    color: var(--black);
    transition: color 250ms;
}

.title:hover a {
    color: var(--blue) !important;
}

.artist {
    font-size: 14px;
    color: var(--highDarkGrey);
    text-align: center;
    margin-top: 4px;
}

.username {
    font-size: 14px;
    color: var(--highDarkGrey);
    text-align: center;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.creatorItem h6 {
    color: var(--black);
    transition: color 200ms;
}
.creatorItem:hover h6 {
    color: var(--blue);
}

@media(max-width: 490px) {
    .textContent {
        margin-top: 8px;
    }

    .title {
        font-size: 14px;
    }
    
    .artist {
        font-size: 13px;
        margin-top: 2px;
    }
}


.artistLink {
    color: var(--highDarkGrey);
}
.artistLink:hover {
    text-decoration: underline;
}


.border {
    box-shadow: var(--shadow-clickable);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    border-radius: var(--border-radius-small-minor);
    z-index: 3;
    transition: box-shadow 200ms;
    overflow: hidden;
    text-indent: 110%;
}
.border.round {
    border-radius: 1000px;
}

:global(.light) .border {
    box-shadow: none;
}
:global(.light) .border.empty {
    box-shadow: var(--shadow-clickable);
}

.border:hover {
    box-shadow: var(--shadow-clickable-active);
}

.borderTitle {
    display: inline-block;
    white-space: nowrap;
    color: var(--highDarkGrey);
    font-weight: 600;
}
