.container {
    padding: 24px;
    background-color: var(--white);
    border-radius: var(--border-radius-mid);
    transition: background-color 150ms;
    box-shadow: var(--shadow-light);
}
.container:hover {
    background-color: var(--hoverColor);
    transition: background-color 0ms;
}
.container:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}

.container .iconContainer {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    background-color: var(--lightGrey);
    transition: background-color 200ms;
}
.container:hover .iconContainer {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.container:active .iconContainer {
    background-color: var(--lightGrey3);
    transition: background-color 0ms;
}

.container .p {
    color: var(--black);
    line-height: 28px;
    font-size: 18px;
    transition: color 200ms;
}

@media(max-width: 900px) {
    .container .p {
        line-height: 26px;
        font-size: 18px;
    }
}
@media(max-width: 490px) {
    .container .p {
        line-height: 24px;
        font-size: 16px;
    }

    .container .iconContainer {
        width: 56px;
        height: 56px;
    }
}