.new {
    width: 74px;
    min-width: 74px;
    max-width: 74px;
    height: 36px;
    min-height: 36px;
    max-height: 36px;
    background-image: url(../../../../assets/images/badges/new-badge-red.jpg);
    background-position: center center;
    background-size: cover;
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(255,255,255,0.15) inset,
                0 1px 2px 0px rgba(0,0,0,0.3);
}

:global(.light) .new {
    box-shadow: 0 0 0 1px rgba(0,0,0,0.15) inset,
                0 1px 2px 0px rgba(0,0,0,0.3);
}
