:root {
    /*
    --shadow-light: 0px 1px 4px rgba(0,0,0,0.07);
    --shadow-dark: 0px 1px 4px rgba(0,0,0,0.14);
    
    --shadow-light-reverse: 0px -1px 4px rgba(0,0,0,0.07);

    --shadow-large-dark: 0px 4px 16px rgba(0,0,0,0.15);
    --shadow-large-light: 0px 4px 16px rgba(0,0,0,0.07);

    --shadow-large-dark-reverse: 0px -2px 16px rgba(0,0,0,0.15);
    --shadow-large-light-reverse: 0px -2px 16px rgba(0,0,0,0.07);

    --border-radius-small: 5px;
    --border-radius-mid: 8px;
    --border-radius-large: 12px;
    */

    --shadow-light: rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-dark: rgb(60 66 87 / 14%) 0px 2px 5px 0px;
    --shadow-light-reverse: rgb(60 66 87 / 14%) 0px -2px 5px 0px;

    --shadow-large-light: 0px 4px 12px rgba(0,0,0,0.12);
    --shadow-large-dark: 0px 4px 12px rgba(0,0,0,0.24);
    --shadow-large-light-reverse: 0px -2px 12px rgba(0,0,0,0.12);
    --shadow-large-dark-reverse: 0px -2px 12px rgba(0,0,0,0.24);

    --twitter: #1D9BF0;
    --discord: #5865F2;
    --linkedin: #0b65c2;
    --instagram: #C32AA3;
    --reddit: #FF5700;
    --facebook: #1977f2;
    --youtube: #FF0000;
    --tiktok: #000000;
    
    --border-radius-mini: 4px;
    --border-radius-small-minor: 7px;
    --border-radius-small: 8px;
    --border-radius-profile-picture: 100px;
    --border-radius-mid: 12px;
    --border-radius-large: 16px;

    --static-button-shadow: rgb(0 0 0 / 18%) 0px -1px 1px 0px inset,  
                            rgb(255 255 255 / 30%) 0px 1px 1px 0px inset,
                            rgb(0 0 0 / 10%) 0px 2px 5px 0px;
    --static-button-shadow-secondary: rgb(0 0 0 / 18%) 0px -1px 1px 0px inset,  
                                      rgb(255 255 255 / 10%) 0px 1px 1px 0px inset,
                                      rgb(0 0 0 / 10%) 0px 2px 5px 0px;
    
    --static-shadow-clickable: rgb(0 0 0 / 18%) 0px 1px 1px 0px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(255 255 255 / 10%) 0px -1px 1px 0px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    
    --shadow-clickable: rgba(4, 2, 2, 0) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                        rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-clickable-hover: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                              rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                              rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                              rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                              rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-clickable-active: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               var(--accent036) 0px 0px 0px 4px, 
                               rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                               rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                               rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-clickable-green: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               var(--green) 0px 0px 0px 2px, 
                               rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                               rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                               rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-clickable-red: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               var(--red) 0px 0px 0px 2px, 
                               rgb(0 0 0 / 12%) 0px 1px 1px 0px, 
                               rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                               rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                               rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                               rgb(60 66 87 / 8%) 0px 2px 5px 0px;

    --shadow-input: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    --shadow-input-hover: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px;
    --shadow-input-active: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(60 66 87 / 16%) 0px 0px 0px 1px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           rgb(0 0 0 / 0%) 0px 0px 0px 0px;

    --shadow-primary: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 12%) 0px 1px 1px 0px,  
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    var(--primary) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-primary-hover: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 20%) 0px 1px 1px 0px,  
                          rgb(0 0 0 / 12%) 0px 0px 0px 1px, 
                          var(--primary) 0px 0px 0px 1px, 
                          rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                          rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-primary-active: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 20%) 0px 1px 1px 0px,  
                           rgb(0 0 0 / 12%) 0px 0px 0px 1px, 
                           var(--primary) 0px 0px 0px 1px, 
                           rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                           rgb(60 66 87 / 8%) 0px 2px 5px 0px;

    --shadow-red: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                  rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                  rgb(0 0 0 / 9%) 0px 1px 1px 0px,  
                  rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                  var(--red) 0px 0px 0px 1px, 
                  rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                  rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-red-hover: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                        rgb(0 0 0 / 14%) 0px 1px 1px 0px,  
                        rgb(0 0 0 / 8%) 0px 0px 0px 1px, 
                        var(--red) 0px 0px 0px 1px, 
                        rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                        rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-red-active: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                         var(--accent036) 0px 0px 0px 4px, 
                         rgb(0 0 0 / 14%) 0px 1px 1px 0px,  
                         rgb(0 0 0 / 8%) 0px 0px 0px 1px, 
                         var(--red) 0px 0px 0px 1px, 
                         rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                         rgb(60 66 87 / 8%) 0px 2px 5px 0px;

    --shadow-green: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 9%) 0px 1px 1px 0px,  
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    var(--green) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-green-hover: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 14%) 0px 1px 1px 0px,  
                          rgb(0 0 0 / 8%) 0px 0px 0px 1px, 
                          var(--green) 0px 0px 0px 1px, 
                          rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                          rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-green-active: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 14%) 0px 1px 1px 0px,  
                           rgb(0 0 0 / 8%) 0px 0px 0px 1px, 
                           var(--green) 0px 0px 0px 1px, 
                           rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                           rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    
    --shadow-yellow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 9%) 0px 1px 1px 0px,  
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    var(--yellow) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-yellow-hover: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 14%) 0px 1px 1px 0px,  
                          rgb(0 0 0 / 8%) 0px 0px 0px 1px, 
                          var(--yellow) 0px 0px 0px 1px, 
                          rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                          rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-yellow-active: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 14%) 0px 1px 1px 0px,  
                           rgb(0 0 0 / 8%) 0px 0px 0px 1px, 
                           var(--yellow) 0px 0px 0px 1px, 
                           rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                           rgb(60 66 87 / 8%) 0px 2px 5px 0px;

    --shadow-black: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(0 0 0 / 25%) 0px 1px 1px 0px, 
                    rgb(60 66 87 / 0%) 0px 0px 0px 0px, 
                    var(--black) 0px 0px 0px 1px, 
                    rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                    rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-black-hover: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                          rgb(0 0 0 / 25%) 0px 1px 1px 0px, 
                          rgb(255 255 255 / 12%) 0px 0px 0px 1px, 
                          var(--black) 0px 0px 0px 1px, 
                          rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                          rgb(60 66 87 / 8%) 0px 2px 5px 0px;
    --shadow-black-active: rgb(0 0 0 / 0%) 0px 0px 0px 0px, 
                           var(--accent036) 0px 0px 0px 4px, 
                           rgb(0 0 0 / 25%) 0px 1px 1px 0px, 
                           rgb(255 255 255 / 12%) 0px 0px 0px 1px, 
                           var(--black) 0px 0px 0px 1px, 
                           rgb(60 66 87 / 8%) 0px 3px 9px 0px, 
                           rgb(60 66 87 / 8%) 0px 2px 5px 0px;
}


html {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* AUTOCOMPLETE TEXT INPUTS */
/* ---------------------------------------- */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* -webkit-text-fill-color: var(--black); */
  /* -webkit-box-shadow: 0 0 0px 1000px var(--white) inset; */
}

/* REMOVE NUMBER ARROWS FOR INPUT */
/* ---------------------------------------- */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

html * {
    margin: 0;
    padding: 0;
}

body {
    background: var(--lightestGrey);
    min-height: 100vh;
}

#root {
    min-height: inherit;
    display: flex;
    flex-direction: column;
}

/* MAIN */
/* ---------------------------------------- */

.heading-div {
    position: absolute;
    margin-top: -2200px;
}

.page-container {
    max-width: 1200px;
    margin: auto;
    padding: 0px 32px;
}
@media(max-width: 1100px) {
    .page-container {
        padding: 0px 16px;
    }
}
@media(max-width: 960px) {
    .page-container {
        padding: 0px 80px;
    }
}
@media(max-width: 860px) {
    .page-container {
        padding: 0px 40px;
    }
}
@media(max-width: 768px) {
    .page-container {
        padding: 0px 16px;
    }
}
@media(max-width: 630px) {
    .page-container {
        padding: 0px 16px;
    }
}

/* FLEX */
/* ---------------------------------------- */
.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.flex-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}
.flex-row-end {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.inline-flex-row {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}
.inline-block {
    display: inline-block;
}
.col-1 {
    flex: 1 1;
}
.col-2 {
    flex: 2 2;
}
.col-3 {
    flex: 3 3;
}
.col-4 {
    flex: 4 4;
}
.col-5 {
    flex: 5 5;
}
.col-6 {
    flex: 6 6;
}
.col-7 {
    flex: 7 7;
}
.col-8 {
    flex: 8 8;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
}
.align-top {
    align-items: flex-start;
}
.align-center {
    align-items: center;
}
.align-end {
    align-items: flex-end;
}
.align-stretch {
    align-items: stretch;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-end {
    justify-content: flex-end;
}
.justify-top {
    justify-content: flex-start;
}
.justify-stretch {
    justify-content: stretch;
}


.flex-column-max-size {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
}



/* GRID */
/* ---------------------------------------- */
.grid-view {
    display: grid;
    column-gap: 16px;
    row-gap: 16px;
}
.grid-view.align-stretch {
    align-items: stretch;
}
.grid-view.no-margin {
    column-gap: 0px;
    row-gap: 0px;
    margin-right: 0px;
}
.grid-view.margin-24 {
    column-gap: 24px;
    row-gap: 24px;
}
.grid-view.large-margin {
    column-gap: 32px;
    row-gap: 32px;
}
.grid-view.one {
    grid-template-columns: repeat(1, 1fr);
}
.grid-view.two {
    grid-template-columns: repeat(2, 1fr);
}
.grid-view.three {
    grid-template-columns: repeat(3, 1fr);
}
.grid-view.four {
    grid-template-columns: repeat(4, 1fr);
}
.grid-view.five {
    grid-template-columns: repeat(5, 1fr);
}
.grid-view.six {
    grid-template-columns: repeat(6, 1fr);
}
.grid-view.seven {
    grid-template-columns: repeat(7, 1fr);
}
.grid-view.eight {
    grid-template-columns: repeat(8, 1fr);
}
.grid-view.ten {
    grid-template-columns: repeat(10, 1fr);
}
.grid-view.twelve {
    grid-template-columns: repeat(12, 1fr);
}

/* MARGIN */
/* ---------------------------------------- */

/* TOP */
.margin-top-2 {
    margin-top: 2px;
}
.margin-top-4 {
    margin-top: 4px;
}
.margin-top-8 {
    margin-top: 8px;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-top-12 {
    margin-top: 12px;
}
.margin-top-16 {
    margin-top: 16px;
}
.margin-top-24 {
    margin-top: 24px;
}
.margin-top-32 {
    margin-top: 32px;
}
.margin-top-40 {
    margin-top: 40px;
}
.margin-top-48 {
    margin-top: 48px;
}
.margin-top-56 {
    margin-top: 56px;
}
.margin-top-64 {
    margin-top: 64px;
}
.margin-top-72 {
    margin-top: 72px;
}
.margin-top-80 {
    margin-top: 80px;
}

/* LEFT */
.margin-left-4 {
    margin-left: 4px;
}
.margin-left-8 {
    margin-left: 8px;
}
.margin-left-12 {
    margin-left: 12px;
}
.margin-left-16 {
    margin-left: 16px;
}
.margin-left-24 {
    margin-left: 24px;
}
.margin-left-32 {
    margin-left: 32px;
}
.margin-left-40 {
    margin-left: 40px;
}
.margin-left-48 {
    margin-left: 48px;
}
.margin-left-56 {
    margin-left: 56px;
}
.margin-left-64 {
    margin-left: 64px;
}
.margin-left-72 {
    margin-left: 72px;
}
.margin-left-80 {
    margin-left: 80px;
}

/* BOTTOM */
.margin-bottom-4 {
    margin-bottom: 4px;
}
.margin-bottom-8 {
    margin-bottom: 8px;
}
.margin-bottom-12 {
    margin-bottom: 12px;
}
.margin-bottom-16 {
    margin-bottom: 16px;
}
.margin-bottom-24 {
    margin-bottom: 24px;
}
.margin-bottom-32 {
    margin-bottom: 32px;
}
.margin-bottom-40 {
    margin-bottom: 40px;
}
.margin-bottom-48 {
    margin-bottom: 48px;
}
.margin-bottom-56 {
    margin-bottom: 56px;
}
.margin-bottom-64 {
    margin-bottom: 64px;
}
.margin-bottom-72 {
    margin-bottom: 72px;
}
.margin-bottom-80 {
    margin-bottom: 80px;
}

/* RIGHT */
.margin-right-4 {
    margin-right: 4px;
}
.margin-right-8 {
    margin-right: 8px;
}
.margin-right-12 {
    margin-right: 12px;
}
.margin-right-16 {
    margin-right: 16px;
}
.margin-right-24 {
    margin-right: 24px;
}
.margin-right-32 {
    margin-right: 32px;
}
.margin-right-40 {
    margin-right: 40px;
}
.margin-right-48 {
    margin-right: 48px;
}
.margin-right-56 {
    margin-right: 56px;
}
.margin-right-64 {
    margin-right: 64px;
}
.margin-right-72 {
    margin-right: 72px;
}
.margin-right-80 {
    margin-right: 80px;
}

@media(max-width: 768px) {
    .margin-top-24 {
        margin-top: 16px;
    }
    .margin-top-32 {
        margin-top: 24px;
    }
    .margin-top-40 {
        margin-top: 32px;
    }
    .margin-top-48 {
        margin-top: 32px;
    }
    .margin-top-56 {
        margin-top: 40px;
    }
    .margin-top-64 {
        margin-top: 40px;
    }
    .margin-top-72 {
        margin-top: 48px;
    }
    .margin-top-80 {
        margin-top: 56px;
    }
    
    .margin-left-24 {
        margin-left: 16px;
    }
    .margin-left-32 {
        margin-left: 24px;
    }
    .margin-left-40 {
        margin-left: 32px;
    }
    .margin-left-48 {
        margin-left: 32px;
    }
    .margin-left-56 {
        margin-left: 40px;
    }
    .margin-left-64 {
        margin-left: 40px;
    }
    .margin-left-72 {
        margin-left: 48px;
    }
    .margin-left-80 {
        margin-left: 56px;
    }

    .margin-right-24 {
        margin-right: 16px;
    }
    .margin-right-32 {
        margin-right: 24px;
    }
    .margin-right-40 {
        margin-right: 32px;
    }
    .margin-right-48 {
        margin-right: 32px;
    }
    .margin-right-56 {
        margin-right: 40px;
    }
    .margin-right-64 {
        margin-right: 40px;
    }
    .margin-right-72 {
        margin-right: 48px;
    }
    .margin-right-80 {
        margin-right: 56px;
    }

    .margin-bottom-24 {
        margin-bottom: 16px;
    }
    .margin-bottom-32 {
        margin-bottom: 24px;
    }
    .margin-bottom-40 {
        margin-bottom: 32px;
    }
    .margin-bottom-48 {
        margin-bottom: 32px;
    }
    .margin-bottom-56 {
        margin-bottom: 40px;
    }
    .margin-bottom-64 {
        margin-bottom: 40px;
    }
    .margin-bottom-72 {
        margin-bottom: 48px;
    }
    .margin-bottom-80 {
        margin-bottom: 56px;
    }

}


/* PADDING */
/* ---------------------------------------- */

.padding-around {
    padding: 16px;
}
.padding-around-8 {
    padding: 8px;
}
.padding-around-24 {
    padding: 24px;
}
.padding-around-32 {
    padding: 32px;
}
.padding-around-64 {
    padding: 64px;
}

@media(max-width: 768px) {
    .padding-around-24 {
        padding: 16px;
    }
    .padding-around-32 {
        padding: 24px;
    }
    .padding-around-64 {
        padding: 48px;
    }
}

/* TOP */
.padding-top-2 {
    padding-top: 2px;
}
.padding-top-4 {
    padding-top: 4px;
}
.padding-top-8 {
    padding-top: 8px;
}
.padding-top-10 {
    padding-top: 10px;
}
.padding-top-12 {
    padding-top: 12px;
}
.padding-top-16 {
    padding-top: 16px;
}
.padding-top-24 {
    padding-top: 24px;
}
.padding-top-32 {
    padding-top: 32px;
}
.padding-top-40 {
    padding-top: 40px;
}
.padding-top-48 {
    padding-top: 48px;
}
.padding-top-64 {
    padding-top: 64px;
}
.padding-top-128 {
    padding-top: 128px;
}

/* LEFT */
.padding-left-4 {
    padding-left: 4px;
}
.padding-left-6 {
    padding-left: 6px;
}
.padding-left-8 {
    padding-left: 8px;
}
.padding-left-12 {
    padding-left: 12px;
}
.padding-left-16 {
    padding-left: 16px;
}
.padding-left-24 {
    padding-left: 24px;
}
.padding-left-32 {
    padding-left: 32px;
}
.padding-left-40 {
    padding-left: 40px;
}
.padding-left-64 {
    padding-left: 64px;
}
.padding-left-128 {
    padding-left: 128px;
}


/* BOTTOM */
.padding-bottom-4 {
    padding-bottom: 4px;
}
.padding-bottom-8 {
    padding-bottom: 8px;
}
.padding-bottom-12 {
    padding-bottom: 12px;
}
.padding-bottom-16 {
    padding-bottom: 16px;
}
.padding-bottom-24 {
    padding-bottom: 24px;
}
.padding-bottom-32 {
    padding-bottom: 32px;
}
.padding-bottom-40 {
    padding-bottom: 40px;
}
.padding-bottom-64 {
    padding-bottom: 64px;
}
.padding-bottom-128 {
    padding-bottom: 128px;
}

/* RIGHT */
.padding-right-4 {
    padding-right: 4px;
}
.padding-right-6 {
    padding-right: 6px;
}
.padding-right-8 {
    padding-right: 8px;
}
.padding-right-12 {
    padding-right: 12px;
}
.padding-right-16 {
    padding-right: 16px;
}
.padding-right-24 {
    padding-right: 24px;
}
.padding-right-32 {
    padding-right: 32px;
}
.padding-right-40 {
    padding-right: 40px;
}
.padding-right-64 {
    padding-right: 64px;
}
.padding-right-128 {
    padding-right: 128px;
}

@media(max-width: 768px) {
    .padding-top-24 {
        padding-top: 16px;
    }
    .padding-top-32 {
        padding-top: 24px;
    }
    .padding-top-40 {
        padding-top: 32px;
    }
    .padding-top-48 {
        padding-top: 40px;
    }
    .padding-top-64 {
        padding-top: 40px;
    }
    .padding-top-128 {
        padding-top: 64px;
    }
    
    .padding-left-24 {
        padding-left: 16px;
    }
    .padding-left-32 {
        padding-left: 24px;
    }
    .padding-left-40 {
        padding-left: 32px;
    }
    .padding-left-64 {
        padding-left: 40px;
    }
    .padding-left-128 {
        padding-left: 64px;
    }

    .padding-right-24 {
        padding-right: 16px;
    }
    .padding-right-32 {
        padding-right: 24px;
    }
    .padding-right-40 {
        padding-right: 32px;
    }
    .padding-right-64 {
        padding-right: 40px;
    }
    .padding-right-128 {
        padding-right: 64px;
    }

    .padding-bottom-24 {
        padding-bottom: 16px;
    }
    .padding-bottom-32 {
        padding-bottom: 24px;
    }
    .padding-bottom-40 {
        padding-bottom: 32px;
    }
    .padding-bottom-64 {
        padding-bottom: 40px;
    }
    .padding-bottom-128 {
        padding-bottom: 64px;
    }

}



/* TEXT */
/* ---------------------------------------- */

/* Main */
html * {
    text-decoration: none;
}

html {
    word-break: break-word;
    color: var(--black);
}

h1, .text-2xl, h2, .text-xl {
    font-weight: 700;
}
h3, h4, h5, h6, strong, .bold, .text-lg, .text-md, .text-sm, .text-xs {
    font-weight: 600;
}
.regular {
    font-weight: 400;
}

/* Sizes */
h1, .text-2xl {
    font-size: 40px;
    line-height: 54px;
}
h2, .text-xl {
    font-size: 32px;
    line-height: 40px;
}
h3, .text-lg {
    font-size: 24px;
    line-height: 30px;
}
h4, .text-md {
    font-size: 20px;
    line-height: 24px;
}
h5, .text-sm {
    font-size: 16px;
    line-height: 20px;
}
h6, .text-xs {
    font-size: 14px;
    line-height: 20px;
}
p {
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
}
.text-mid {
    font-size: 16px;
    line-height: 24px;
}

html a {
    color: var(--black);
}

/* Weights */
.medium {
    font-weight: 500;
}

/* Colors */
.black:not(.theme-class) {
    color: var(--black);
}
.constantBlack {
    color: var(--constantBlack);
}
.white {
    color: var(--white);
}
.constantWhite {
    color: var(--constantWhite);
}
.grey {
    color: var(--highDarkGrey);
}
.highDarkGrey {
    color: var(--highDarkGrey);
}
.darkGrey {
    color: var(--darkGrey);
}
.darkLightGrey {
    color: var(--darkLightGrey);
}
.lightGrey {
    color: var(--lightGrey);
}
.lightestGrey {
    color: var(--lightestGrey);
}
.deepBlue {
    color: var(--deepBlue);
}
.blue:not(.theme-class) {
    color: var(--blue);
}
.red:not(.theme-class) {
    color: var(--red);
}
.green:not(.theme-class) {
    color: var(--green);
}
.yellow {
    color: var(--yellow);
}
.textColor {
    color: var(--textColor);
}

.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

/* RANDOM */
/* ---------------------------------------- */
.cursor {
    cursor: pointer;
}
.cursor-default {
    cursor: default;
}

.full-height {
    height: calc(100vh - 55px);
}

.full-center {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.margin-auto {
    margin: auto;
}

.top-border {
    border-top: 1px solid var(--lightGrey); 
    padding-top: 12px;
}

.one-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: initial;
}
.grid {
    display: grid;
}

a:hover .underline {
    text-decoration: underline;
}

a > p {
    transition: color 200ms;
}
a > h2 {
    transition: color 200ms;
}
a > span {
    transition: color 200ms;
}
a:hover > p {
    color: var(--blue);
}
a:hover > h2 {
    color: var(--blue);
}
a:hover > span {
    color: var(--blue);
}

.bottom-divider {
    border-bottom: 1px solid var(--lightGrey);
}
.bottom-divider-bright {
    border-bottom: 1px solid var(--darkLightGrey);
}
.border-around {
    border: 1px solid var(--lightGrey);
}
.border-bottom {
    border-bottom: 1px solid var(--lightGrey);
}
.border-bright-bottom {
    border-bottom: 1px solid var(--darkLightGrey);
}
.border-top {
    border-top: 1px solid var(--lightGrey);
}
.border-bright-top {
    border-top: 1px solid var(--darkLightGrey);
}
.border-right {
    border-right: 1px solid var(--lightGrey);
}
.border-right-bright {
    border-right: 1px solid var(--darkLightGrey);
}
.border-left {
    border-left: 1px solid var(--lightGrey);
}
.border-left-bright {
    border-left: 1px solid var(--darkLightGrey);
}

.border-radius-small {
    border-radius: var(--border-radius-small);
}
.border-radius-mid {
    border-radius: var(--border-radius-mid);
}
.border-radius-large {
    border-radius: var(--border-radius-large);
}

.dot {
    max-width: 6px;
    max-height: 6px;
    min-width: 6px;
    min-height: 6px;
    background-color: var(--highDarkGrey);
    border-radius: 1000px;
}

/* LOAD MORE */
/* ------------------------------------ */
.load-more {
    height: 36px !important;
    background-color: var(--white) !important;
    transition: background-color 200ms !important;
}
.load-more:hover {
    height: 36px !important;
    background-color: var(--lightGrey) !important;
}

.load-more.light {
    background-color: var(--lightGrey) !important;
}
.load-more.light:hover {
    background-color: var(--darkLightGrey) !important;
}

.load-more .load-more-text {
    color: var(--highDarkGrey) !important;
    transition: color 200ms !important;
}
.load-more.light .load-more-text {
    color: var(--highDarkGrey) !important;
}
.load-more:hover .load-more-text {
    color: var(--black) !important;
}


/* EMPTY TEXT */
/* --------------------------------------- */
.empty-text {
    color: var(--highDarkGrey);
    border-radius: var(--border-radius-mini);
    text-align: center;
    padding: 64px 32px;
}
.empty-text-light {
    color: var(--highDarkGrey);
    border-radius: var(--border-radius-mini);
    text-align: center;
    padding: 64px 32px;
}

/* SLIDE/CAROUSEL */
/* --------------------------------------- */
.backward .slide-exit{
	transform: translateX(0px);
	opacity: 1;
}
.backward .slide-exit.slide-exit-active{
	transform: translateX(100px);
	opacity: 0;
	transition: transform 400ms ease-out, opacity 300ms ease-out;
}
.backward .slide-enter{
	opacity: 0;
	z-index: 1;

	transform: translateX(-100px);
}
.backward .slide-enter.slide-enter-active{
	opacity: 1;
	transform: translateX(0px);
	transition: opacity 400ms ease-out, transform 500ms ease-out;
}

.forward .slide-exit{
	transform: translateX(0px);
	opacity: 1;
}
.forward .slide-exit.slide-exit-active{
	transform: translateX(-100px);
	opacity: 0;
	transition: transform 400ms ease-out, opacity 300ms ease-out;
}
.forward .slide-enter{
	opacity: 0;
	z-index: 1;

	transform: translateX(100px);
}
.forward .slide-enter.slide-enter-active{
    opacity: 1;
    transform: translateX(0px);
	transition: opacity 400ms ease-out, transform 500ms ease-out;
}
/* FULL SLIDE */
.backward .full-slide-exit{
	transform: translateX(0px);
}
.backward .full-slide-exit.full-slide-exit-active{
	transform: translateX(100%);
	transition: transform 500ms ease-out;
}
.backward .full-slide-enter{
	z-index: 1;
	transform: translateX(-100%);
}
.backward .full-slide-enter.full-slide-enter-active{
	transform: translateX(0px);
	transition: transform 500ms ease-out;
}

.forward .full-slide-exit{
	transform: translateX(0px);
}
.forward .full-slide-exit.full-slide-exit-active{
	transform: translateX(-100%);
	transition: transform 500ms ease-out;
}
.forward .full-slide-enter{
	z-index: 1;
	transform: translateX(100%);
}
.forward .full-slide-enter.full-slide-enter-active{
    transform: translateX(0px);
	transition: transform 500ms ease-out;
}

.fade-exit{
    opacity: 1;
    z-index: -1;
}
.fade-exit.fade-exit-active{
	opacity: 0;
	transition:opacity 300ms ease-out;
}
.fade-enter{
	opacity: 0;
}
.fade-enter.fade-enter-active{
	opacity: 1;
	transition: opacity 400ms ease-out;
}

.step {
    width: 100%;
    position: absolute;
    top: 0px;
}

/* SECTIONS */
/* ------------------------------------ */
.section-handler {
    display: flex;
    flex-direction: row;
}
.section-handler-reverse {
    display: flex;
    flex-direction: row;
}
.section-handler-end {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.section-handler-end-reverse {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.section-5 {
    flex: 5 5;
}
.section-4 {
    flex: 4 4;
}
.section-3 {
    flex: 3 3;
}
.section-2 {
    flex: 2 2;
}
.section-1 {
    flex: 1 1;
}
.section-margin {
    margin-right: 32px;
}
.section-margin-left {
    margin-left: 32px;
}
@media(max-width: 1100px) {
    .section-margin {
        margin-right: 16px;
    }
    .section-margin-left {
        margin-left: 16px;
    }
}
@media(max-width: 960px) {
    .section-handler {
        flex-direction: column;
        align-items: stretch;
    }
    .section-handler-reverse {
        flex-direction: column-reverse;
        align-items: stretch;
    }
    .section-handler-end {
        flex-direction: column;
        align-items: stretch;
    }
    .section-handler-end-reverse {
        flex-direction: column-reverse;
        align-items: stretch;
    }
    .section-margin {
        margin-right: 0px;
    }
    .section-margin-left {
        margin-left: 0px;
    }
}

/* SECTION-BLOCK */
/* ------------------------------------ */
.section-block {
    background-color: var(--white);
    border-radius: var(--border-radius-mid);
    box-shadow: var(--shadow-light);
}

@media(max-width: 630px) {
    .section-block {
        margin-left: -16px;
        margin-right: -16px;
        border-radius: 0px;
    }
    .mobile-block {
        margin-left: -16px !important;
        margin-right: -16px !important;
        border-radius: 0px !important;
    }
}


/* CLAMP-LINES BUTTON */
/* ------------------------------------ */
.clamp-lines-wrapper .clamp-lines {
    position: relative;
}

.clamp-lines-wrapper .clamp-lines__button[aria-expanded=false]::before,
.clamp-lines-wrapper .clamp-lines__button[aria-expanded=false]::after {
    content: '';
    height: 40px;
    width: calc(100% + 32px);
    position: absolute;
    bottom: 34px;
    left: 0;
    pointer-events: none;
    margin-left: -16px;
}

.clamp-lines-wrapper .clamp-lines__button[aria-expanded=false]::before {
    background: linear-gradient(
        var(--whiteTransparent),
        var(--white)
    );
    opacity: 1;
}
.clamp-lines-wrapper:hover .clamp-lines__button[aria-expanded=false]::before {
    opacity: 0;
}

.clamp-lines-wrapper .clamp-lines__button[aria-expanded=false]::after {
    opacity: 0;
    transition: opacity 200ms;
}
.clamp-lines-wrapper:hover .clamp-lines__button[aria-expanded=false]::after {
    background: linear-gradient(
        var(--hoverColorTransparent),
        var(--hoverColor)
    );
    opacity: 1;
    transition: opacity 0ms;
}

.clamp-lines-wrapper .clamp-lines__button[aria-expanded=false] {
    width: 100%;
    padding: 8px;
    border-radius: var(--border-radius-small);
    border: 1px solid var(--darkLightGrey);
    background-color: var(--white);
    color: var(--black);
    cursor: pointer;
    outline: none;
    transition: background-color 200ms;
    font-weight: 500;
    font-size: 14px;
    pointer-events: all;
}

.light .clamp-lines-wrapper .clamp-lines__button[aria-expanded=false] {
    background-color: var(--white);
    border: 1px solid var(--lightGrey);
    transition: border 200ms;
}

.clamp-lines-wrapper .clamp-lines__button[aria-expanded=false]:hover {
    background-color: var(--darkLightGrey);
    transition: background-color 0ms;
}

.light .clamp-lines-wrapper .clamp-lines__button[aria-expanded=false]:hover {
    background-color: var(--white);
    border: 1px solid var(--blue);
    transition: border 200ms;
}

.clamp-lines-wrapper .clamp-lines__button[aria-expanded=true] {
    width: 0;
    height: 0;
    display: none;
}

/* PARSE USER TEXT */
/* ------------------------------------ */
.hover-underline:hover {
    text-decoration: underline;
}

/* LINK */
/* ------------------------------------ */
.link-wrapper {
    position:relative;
}

.link-wrapper .link-overlay {
    position:absolute;
    left:0; top:0; bottom:0; right:0;
    overflow: hidden;
    text-indent: 110%;
}

.link-wrapper .link-overlay .link-overlay-title {
    display: inline-block;
    white-space: nowrap;
    color: var(--highDarkGrey);
    font-weight: 600;
}

.link-wrapper .link-inner {
    position:relative;
    pointer-events: none;
}

.link-wrapper .link-inner a {
    pointer-events: all;
}
.link-wrapper .link-inner a, .link-wrapper .link-inner .link-clickable {
    pointer-events: all;
}

/* INLINE ICON */
/* ------------------------------------ */
.small-inline-icon {
    width: 19px;
    transform: translateY(4.4px);
    margin-top: -4px;
    overflow: hidden;
}

.inline-icon {
    width: 24px;
    transform: translateY(6px);
    margin-top: -6px;
    overflow: hidden;
}

.inline-link {
    color: var(--black);
    transition: color 200ms;
}
.inline-link:hover {
    color: var(--blue);
    transition: color 120ms;
}

.text-underline-hover {
    color: var(--highDarkGrey);
}
.text-underline-hover:hover {
    text-decoration: underline;
}


/* INLINE ICON */
/* ------------------------------------ */
.background-clickable {
    background-color: transparent;
    transition: background-color 150ms;
    border-radius: var(--border-radius-mid);
}
.background-clickable:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.background-clickable:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.light .background-clickable:hover {
    background-color: var(--lightestGrey);
}
.background-clickable-bright {
    background-color: transparent;
    transition: background-color 150ms;
    border-radius: var(--border-radius-mid);
}
.background-clickable-bright:hover {
    background-color: var(--hoverColor);
    transition: background-color 0ms;
}
.background-clickable-bright:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}

/* Icon buttons */
.icon-btn {
    
}
.icon-btn {
    margin: -4px;
    padding: 4px;
    cursor: pointer;
    opacity: 1;
    transform: scale(1.0);
    transition: transform 150ms ease-out, opacity 150ms;
    opacity: 0.95;
}
.icon-btn.large-clickable {
    margin: -8px;
    padding: 8px;
}

.icon-btn:hover {
    opacity: 1.0;
    transform: scale(1.1);
}
.icon-btn.small-scale:hover {
    transform: scale(1.05);
}

.icon-btn:active {
    transition: transform 200ms ease-out, opacity 150ms;
    transform: scale(1.0);
}
.icon-btn.small-scale:active {
    transform: scale(1.0);
}

/* REACT-TAG-INPUT */

/* Wrapper */
.react-tag-input {
    padding: 4px 4px !important;
    background-color: var(--lightestGrey) !important;
    border: 1px solid var(--lightGrey) !important;
} 
/* Inner input */
.react-tag-input__input {
    font-size: 14px !important;
    color: var(--black) !important;
    padding: 0 0 0 2px !important;
    height: 21px !important;
} 
.react-tag-input__input::placeholder {
    color: var(--highDarkGrey) !important;
} 
/* Tag wrapper */
.react-tag-input__tag {
    background-color: var(--black) !important;
    font-size: 14px !important;
    border-radius: var(--border-radius-small) !important;
} 
.react-tag-input>* {
    margin: 4px !important;
}
/* The text content within the tag */
.react-tag-input__tag__content {
    padding: 0px 8px !important;
    color: var(--white) !important;
} 
/* The remove button & icon for a tag */
.react-tag-input__tag__remove {
    width: 24px !important;
    height: 24px !important;
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
    background-color: var(--darkGrey) !important;
} 
.react-tag-input__tag__remove::before {
    background-color: var(--white) !important;
}
.react-tag-input__tag__remove::after {
    background-color: var(--white) !important;
}