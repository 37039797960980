.icon {
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: var(--black);
}

@keyframes rotate {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}
.loading {
    animation: rotate 1500ms linear 0ms infinite forwards;
}

.loading {
    mask-image: url(../../assets/icons/disc.svg);
    background-color: var(--yellow) !important;
}

.apple {
    mask-image: url(../../assets/icons/apple.svg);
}
.android {
    mask-image: url(../../assets/icons/android.svg);
}

.facebook {
    background-image: url(../../assets/icons/facebook-filled.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    background-color: none;
    mask-position: initial;
    mask-repeat: initial;
    mask-size: initial;
    border-radius: 1000px;
}
.person-insight-dark {
    background-image: url(../../assets/icons/person-insight-color-dark.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    background-color: none;
    mask-position: initial;
    mask-repeat: initial;
    mask-size: initial;
}
.auto-fix-high {
    background-image: url(../../assets/icons/auto-fix-high.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    background-color: none;
    mask-position: initial;
    mask-repeat: initial;
    mask-size: initial;
}

.facebook-alt {
    mask-image: url(../../assets/icons/facebook.svg);
}
.twitter {
    mask-image: url(../../assets/icons/twitter.svg);
}
.x {
    mask-image: url(../../assets/icons/x.svg);
}
.x-filled {
    mask-image: url(../../assets/icons/x-filled.svg);
}

.artist {
    mask-image: url(../../assets/icons/artost.svg);
}
.news {
    mask-image: url(../../assets/icons/news.svg);
}
.dice {
    mask-image: url(../../assets/icons/dice.svg);
}
.thumb {
    mask-image: url(../../assets/icons/thumbtack.svg);
}
.list-number {
    mask-image: url(../../assets/icons/list-number.svg);
}
.contribute {
    mask-image: url(../../assets/icons/add-disc.svg);
}
.lightbulb-alt {
    mask-image: url(../../assets/icons/lightbulb.svg);
}

.microphone {
    mask-image: url(../../assets/icons/microphone.svg);
}
.microphone-old {
    mask-image: url(../../assets/icons/microphone-old.svg);
}
.guitar {
    mask-image: url(../../assets/icons/guitar.svg);
}
.keyboard {
    mask-image: url(../../assets/icons/keyboard.svg);
}
.trumpet {
    mask-image: url(../../assets/icons/trumpet.svg);
}
.saxophone {
    mask-image: url(../../assets/icons/saxophone.svg);
}
.masks {
    mask-image: url(../../assets/icons/masks.svg);
}
.gramophone {
    mask-image: url(../../assets/icons/gramophone.svg);
}
.boombox {
    mask-image: url(../../assets/icons/boombox.svg);
}
.banjo {
    mask-image: url(../../assets/icons/banjo.svg);
}
.drum {
    mask-image: url(../../assets/icons/drum.svg);
}
.maracas {
    mask-image: url(../../assets/icons/maracas.svg);
}
.alternative {
    mask-image: url(../../assets/icons/alternative.svg);
}
.glasses {
    mask-image: url(../../assets/icons/glasses.svg);
}
.check-circle-light {
    mask-image: url(../../assets/icons/check-circle-light.svg);
}
.remove-light {
    mask-image: url(../../assets/icons/remove-light.svg);
}
.rocket {
    mask-image: url(../../assets/icons/rocket.svg);
}
.gift {
    mask-image: url(../../assets/icons/gift.svg);
}
.fa-ban {
    mask-image: url(../../assets/icons/fa-ban.svg);
}
.fa-tools {
    mask-image: url(../../assets/icons/fa-tools.svg);
}
.fa-image-landscape {
    mask-image: url(../../assets/icons/fa-image-landscape.svg);
}
.fa-badge {
    mask-image: url(../../assets/icons/fa-badge.svg);
}
.fa-align-left {
    mask-image: url(../../assets/icons/fa-align-left.svg);
}
.fa-paintbrush {
    mask-image: url(../../assets/icons/fa-paintbrush.svg);
}
.fa-gif {
    mask-image: url(../../assets/icons/fa-gif.svg);
}
.fa-icons {
    mask-image: url(../../assets/icons/fa-icons.svg);
}
.fa-chart-simple {
    mask-image: url(../../assets/icons/fa-chart-simple.svg);
}
.fa-palette {
    mask-image: url(../../assets/icons/fa-palette.svg);
}
.fa-ellipsis {
    mask-image: url(../../assets/icons/fa-ellipsis.svg);
}
.comments-disabled {
    mask-image: url(../../assets/icons/comments-disabled.svg);
}
.logout {
    mask-image: url(../../assets/icons/logout.png);
}

.spotify {
    mask-image: url(../../assets/images/spotify-white.png);
}

.apple-login {
    mask-image: url(../../assets/icons/apple-login.svg);
}
.google-login {
    mask-image: url(../../assets/icons/google-login.svg);
}

.thumb-up-border {
    mask-image: url(../../assets/icons/thumb-up-border.svg);
}


.materialIcons {
    color: var(--black);
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

/*

.verified {
    mask-image: url(../../assets/icons/verified.svg);
}
.star {
    mask-image: url(../../assets/icons/star.svg);
}
.sync {
    mask-image: url(../../assets/icons/sync.svg);
}
.favorite {
    mask-image: url(../../assets/icons/favorite.svg);
}
.favorite-border {
    mask-image: url(../../assets/icons/favorite-border.svg);
}
.burger {
    mask-image: url(../../assets/icons/burger.svg);
}
.search {
    mask-image: url(../../assets/icons/search.svg);
}
.disc {
    mask-image: url(../../assets/icons/disc.svg);
}
.person {
    mask-image: url(../../assets/icons/person.svg);
}
.arrow-forward {
    mask-image: url(../../assets/icons/arrow-forward.svg);
}
.close {
    mask-image: url(../../assets/icons/close.svg);
}
.cancel {
    mask-image: url(../../assets/icons/cancel.svg);
}
.time {
    mask-image: url(../../assets/icons/time.svg);
}
.more-horiz {
    mask-image: url(../../assets/icons/more-horiz.svg);
}
.chevron-left {
    mask-image: url(../../assets/icons/chevron-left.svg);
}
.chevron-right {
    mask-image: url(../../assets/icons/chevron-right.svg);
}
.chevron-down {
    mask-image: url(../../assets/icons/chevron-down.svg);
}
.chevron-up {
    mask-image: url(../../assets/icons/chevron-up.svg);
}
.edit {
    mask-image: url(../../assets/icons/edit.svg);
}
.thumbtack {
    mask-image: url(../../assets/icons/thumbtack.svg);
}
.review {
    mask-image: url(../../assets/icons/review.svg);
}
.home {
    mask-image: url(../../assets/icons/home.svg);
}
.toggle-on {
    mask-image: url(../../assets/icons/toggle-on.svg);
}
.toggle-off {
    mask-image: url(../../assets/icons/toggle-off.svg);
}
.fire {
    mask-image: url(../../assets/icons/fire.svg);
}
.people {
    mask-image: url(../../assets/icons/people.svg);
}
.trophy {
    mask-image: url(../../assets/icons/trophy.svg);
}
.settings {
    mask-image: url(../../assets/icons/settings.svg);
}
.mail {
    mask-image: url(../../assets/icons/mail.svg);
}
.feedback {
    mask-image: url(../../assets/icons/feedback.svg);
}
.chat {
    mask-image: url(../../assets/icons/chat.svg);
}
.add {
    mask-image: url(../../assets/icons/add.svg);
}
.star-border {
    mask-image: url(../../assets/icons/star-border.svg);
}
.lock {
    mask-image: url(../../assets/icons/lock.svg);
}
.unlock {
    mask-image: url(../../assets/icons/unlock.svg);
}
.check {
    mask-image: url(../../assets/icons/check.svg);
}
.eye {
    mask-image: url(../../assets/icons/eye.svg);
}
.key {
    mask-image: url(../../assets/icons/key.svg);
}
.bell {
    mask-image: url(../../assets/icons/bell.svg);
}
.add-circle {
    mask-image: url(../../assets/icons/add-circle.svg);
}
.add-box {
    mask-image: url(../../assets/icons/add-box.svg);
}
.share {
    mask-image: url(../../assets/icons/share.svg);
}
.explicit {
    mask-image: url(../../assets/icons/explicit.svg);
}
.return {
    mask-image: url(../../assets/icons/return.svg);
}
*/