.item {
    padding: 4px 0px;
}
.itemText {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.item:hover .itemText {
    color: var(--black);
}
.item.active .itemText {
    color: var(--black);
}

.arrow {
    transform: rotate(0deg);
    transition: transform 150ms ease-out;
}
.arrow.active {
    transform: rotate(180deg);
}