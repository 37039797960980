@font-face {
    font-family: 'Neurial Grotesk';
    src: url('../fonts/NeurialGrotesk-Bold.eot');
    src: url('../fonts/NeurialGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NeurialGrotesk-Bold.woff2') format('woff2'),
        url('../fonts/NeurialGrotesk-Bold.woff') format('woff'),
        url('../fonts/NeurialGrotesk-Bold.ttf') format('truetype'),
        url('../fonts/NeurialGrotesk-Bold.svg#NeurialGrotesk-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Neurial Grotesk';
    src: url('../fonts/NeurialGrotesk-Regular.eot');
    src: url('../fonts/NeurialGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NeurialGrotesk-Regular.woff2') format('woff2'),
        url('../fonts/NeurialGrotesk-Regular.woff') format('woff'),
        url('../fonts/NeurialGrotesk-Regular.ttf') format('truetype'),
        url('../fonts/NeurialGrotesk-Regular.svg#NeurialGrotesk-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Neurial Grotesk';
    src: url('../fonts/NeurialGrotesk-Extrabold.eot');
    src: url('../fonts/NeurialGrotesk-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NeurialGrotesk-Extrabold.woff2') format('woff2'),
        url('../fonts/NeurialGrotesk-Extrabold.woff') format('woff'),
        url('../fonts/NeurialGrotesk-Extrabold.ttf') format('truetype'),
        url('../fonts/NeurialGrotesk-Extrabold.svg#NeurialGrotesk-Extrabold') format('svg');
    font-weight: 800;
    font-style: normal;
}