.container {
    height: 120px;
    overflow: hidden;
    position: relative;
}
.container.open {
    height: initial;
    overflow: initial;
    position: relative;
}

.fade {
    position: absolute;
    width: 100%;
    height: 48px;
    bottom: 0;
    background: linear-gradient(var(--lightestGreyTransparent), var(--lightestGrey));
}
.btn {
    position: absolute;
    bottom: 0;
    right: 0;

    margin-right: -36px;
    margin-bottom: -24px;
    padding: 24px;
    padding-right: 40px;
    background: radial-gradient(var(--lightestGrey), var(--lightestGreyTransparent));

    color: var(--black);
    cursor: pointer;
    font-size: 16px;
    line-height: 1.5;
    transition: color 200ms;
}
.btn:hover {
    color: var(--blue);
    transition: color 0ms;
}