.container {
}

.dropdown {
    padding: 0px !important;
    border: none !important;
    overflow: hidden;
}

.mobileWrapper {
    z-index: 1300;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.4);
}
.mobileOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.mobilePickerWrapper {
    background-color: #fff;
    border-radius: var(--border-radius-small);
    overflow: hidden;
}

.input {
    min-width: 101px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    border-radius: var(--border-radius-small);
    box-shadow: var(--shadow-clickable);
    transition: box-shadow 200ms;
}
.input:hover {
    box-shadow: var(--shadow-clickable-hover);
}
.input:active, .input.active {
    box-shadow: var(--shadow-clickable-active);
}

.clearRatingWrapper {
    position: relative;
}

.clearRatingBtn {
    position: absolute;
    right: -36px;
    transform: translateX(-20px);
    opacity: 0;
    pointer-events: none;
    transition: transform 200ms, opacity 200ms;
    padding: 4px 8px;
    cursor: pointer;
}
.clearRatingBtn .clearRatingIcon {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.clearRatingBtn:hover .clearRatingIcon {
    color: var(--black);
    transition: color 100ms;
}
.clearRatingWrapper:hover .clearRatingBtn.active {
    transform: translateX(0px);
    opacity: 1;
    pointer-events: all;
}