.socialsWrapper {
    position: relative;
    margin: 0px -24px;
}
.socialsWrapper.scrollbar {
    margin: 0px 0px;
    padding: 8px;
    margin-top: 16px;
    background-color: var(--white);
    border-radius: var(--border-radius-mid);
}

.socialsGradient {
    width: 0px;
    height: 100%;
    position: absolute;
    top: 0px;
    z-index: 1;
    pointer-events: none;
}
.socialsGradient.left {
    left: 0px;
    background: linear-gradient(270deg, var(--lightGreyTransparent) 0%, var(--lightGrey) 100%);
}
.socialsGradient.right {
    right: 0px;
    background: linear-gradient(90deg, var(--lightGreyTransparent) 0%, var(--lightGrey) 100%);
}

.socialsScroll {
    overflow-x: auto;
    box-sizing: border-box;
    padding: 16px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.socialsWrapper.scrollbar .socialsScroll {
    padding: 8px 0px 16px 0px;
}

.socialItem {
    cursor: pointer;
    margin: 0px 4px;
    transition: opacity 150ms;
}
.socialItem:hover {
    opacity: 0.8;
    transition: opacity 0ms;
}
.socialItem:active {
    opacity: 0.65;
    transition: opacity 0ms;
}

.socialImg {
    width: 64px;
    height: 64px;
    display: block;
    border-radius: 100px;
}

.socialName {
    width: 80px;
    text-align: center;
}

.link {
    cursor: pointer;
    color: var(--blue);
}
.link:hover {
    text-decoration: underline;
}

.input {
    position: absolute;
    left: -5000px;
    top: -5000px;
}
