.StickerSection-flex-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    margin-bottom: -16px;
}

.StickerSection-btn-left-margin {
    margin-left: 21px !important;
}
