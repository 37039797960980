.myRatingContainer {
    position: relative;
}

.myRating {
    height: 51px;
    background-color: var(--lightGrey);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-small);
}

:global(.light) .myRating {
    background-color: var(--lightestGrey);
}

.myRatingOverlayBtn {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
}

.myRatingContainer:hover .myRatingOverlayBtn {
    opacity: 0;
}

.a h5 {
    color: var(--black);
    transition: color 200ms;
}
.a:hover h5 {
    color: var(--blue);
}

.artistPicture {
    margin-left: -16px;
}

.artistPictureFirst {
    margin-left: 0;
}

.artistList {
    
}

.artistLink {
    color: var(--black);
    transition: color 200ms;
}

.artistLink:hover {
    color: var(--blue);
}

@keyframes fadeInLoadingInformation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.loadingInformation {
    color: var(--grey);
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    position: absolute;
    width: 100%;
    max-width: 490px;
    transform: translateY(104px);
    animation: fadeInLoadingInformation 130ms 1 forwards;
}