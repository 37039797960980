.container {
    height: 200px;
    overflow: hidden;
    position: relative;
}

:global(.truncate-wrapper) .fade {
    height: 40px;
    width: calc(100% + 32px);
    position: absolute;
    bottom: 36px;
    margin-left: -16px;
    margin-right: -16px;
    pointer-events: none;
}
.fade.showFade {
    bottom: -4px;
}

:global(.truncate-wrapper) .fade.dark {
    background: linear-gradient(var(--whiteTransparent), var(--white));
    opacity: 1;
    transition: opacity 100ms;
}
:global(.truncate-wrapper):hover .fade.dark {
    opacity: 0;
    transition: opacity 0ms;
}

:global(.truncate-wrapper) .fade.hover {
    background: linear-gradient(var(--hoverColorTransparent), var(--hoverColor));
    opacity: 0;
    transition: opacity 150ms;
}
:global(.truncate-wrapper):hover .fade.hover {
    opacity: 1;
    transition: opacity 0ms;
}

:global(.truncate-wrapper) .fade.active {
    background: linear-gradient(var(--lightGrey2Transparent), var(--lightGrey2));
    opacity: 0;
    transition: opacity 0ms;
}
:global(.truncate-wrapper):active .fade.hover {
    opacity: 0;
    transition: opacity 0ms;
}
:global(.truncate-wrapper):active .fade.active {
    opacity: 1;
    transition: opacity 0ms;
}

.btn {
    height: 34px;
    padding: 0px 12px;
    width: 100%;
    border-radius: var(--border-radius-small);
    
    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--white);
    box-shadow: var(--shadow-clickable);
    transition: box-shadow 200ms, background-color 200ms;
    z-index: 100;
}
.btn:hover {
    box-shadow: var(--shadow-clickable-hover);
}
.btn:active {
    box-shadow: var(--shadow-clickable-active);
}

.btn p {
    color: var(--black);
    font-weight: 500;
}