.tools {
    height: 120px;
    width: 120px;
    background-image: url(../../assets/icons/tools-dark.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
:global(.light) .tools {
    background-image: url(../../assets/icons/tools-light.svg);
}

.donda {
    overflow: hidden;
    position: relative;
    border-radius: var(--border-radius-small);
    box-shadow: var(--shadow-dark);
}
.image {
    height: 180px;
    width: 320px;
    background-image: url(../../assets/images/kanye-donda-4.gif);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}