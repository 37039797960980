.page {
    padding: 6px 10px;
    cursor: pointer;
    border-radius: var(--border-radius-small);
    color: var(--highDarkGrey);
    transition: background-color 150ms, color 150ms;
}
.page:hover {
    color: var(--black);
    background-color: var(--lightGrey);
    transition: background-color 0ms, color 0ms;
}
.page:active {
    color: var(--black);
    background-color: var(--lightGrey2);
    transition: background-color 0ms, color 0ms;
}
.page.active {
    color: var(--black);
    background-color: var(--lightGrey2);
    cursor: default;
}

.pageWrapper {
    margin: 0px 2px;
    color: var(--highDarkGrey);
}