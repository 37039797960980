.line {
    height: 1px;
    width: 100%;
    background-color: var(--lightGrey);
}

.text {
    color: var(--highDarkGrey);
    line-height: 24px;
    font-size: 15px;
}

.text a {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.text a.pro {
    color: var(--yellow);
}

.text a:hover {
    color: var(--blue);
    transition: color 0ms;
}