
/* CONTAINER */

@keyframes moveIn {
    from {
        transform: translateY(87px);
    }
    to {
        transform: translateY(0px);
    }   
}

.container {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 86px;
    background-color: var(--white);
    border-top: 1px solid var(--lightGrey);
    box-shadow: var(--shadow-light-reverse);
    z-index: 2122;
    animation: moveIn 200ms ease-out 0ms 1 forwards normal;
}

@keyframes moveOut {
    from {
        transform: translateY(0px);
    }
    to {
        transform: translateY(87px);
    }   
}

.container.closing {
    animation: moveOut 200ms ease-out 0ms 1 forwards normal;
}

/* MINIMIZED  */

.container.minimized {
    box-sizing: border-box;
    position: fixed;
    bottom: 8px;
    left: 8px;
    width: min-content;
    height: 74px;
    border: 1px solid var(--lightGrey);
    border-radius: var(--border-radius-mid);
    box-shadow: 0px 3px 12px rgba(0,0,0,0.08);
    overflow: hidden;
}

/* OTHER  */

.iconMg {
    display: inline-block;
    margin: 0 8px;
}

.iconMgSmall {
    margin: 0 11px;
}


.progressContainer {
    width: 100%;
    position: relative;
}
.progressContainer.volumeContainer {
    width: 64px;
    margin-right: 12px;
}
.progressContainer.volumeVerticalContainer {
    height: 64px;
}

.progressSliderWrapper {
    height: 4px;
    border-radius: 4px;
    background-color: var(--grey);
    position: relative;
    overflow: hidden;
}

.progressContainer.volumeVerticalContainer .progressSliderWrapper {
    width: 4px;
    height: 100%;
}

.progressSliderInner {
    width: 0%;
    height: 100%;
    border-radius: 4px;
    background-color: var(--black);
    position: absolute;
    left: 0;
}
.progressContainer:hover .progressSliderInner {
    background-color: var(--blue);
}

.progressContainer.volumeVerticalContainer .progressSliderInner {
    width: 100%;
    height: 0%;
    bottom: 0;
}

.progressSliderThumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--black);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    margin-left: -4.5px;
    opacity: 0;
}

.progressContainer:hover .progressSliderThumb {
    opacity: 1;
}

.progressContainer.volumeVerticalContainer .progressSliderThumb {
    top: unset;
    transform: translateX(-50%);
    left: 50%;
    margin-left: 0px;
    margin-bottom: -6px;
    bottom: 0;
    opacity: 1;
}

.progressSlider {
    width: calc(100% + 12px);
    height: 100%;
    position: absolute;
    left: -6px;
    top: 0;
    cursor: pointer;
    opacity: 0;
}

.progressContainer.volumeVerticalContainer .progressSlider {
    width: 100%;
    height: calc(100% + 12px);
    top: -6px;
    left: 0;

    writing-mode: vertical-lr;
    direction: rtl;
    appearance: slider-vertical;
    vertical-align: bottom;
    touch-action: none;
    transform: scaleY(1);
}


.title {
    color: var(--black);
    transition: color 200ms;
}
.title:hover {
    color: var(--blue);
}

.artist {
    color: var(--highDarkGrey) !important;
}
.artist:hover {
    text-decoration: underline;
}

@media (max-width: 860px) {
    .title {
        font-size: 14px;
        line-height: 18px;
    }
    .artist {
        font-size: 13px;
        line-height: 16px;
    }

    .iconMgSmall {
        margin: 0 10px;
    }
    
}


.volumeSliderWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.volumeSliderContainer {
    position: absolute;
    bottom: 0px; /* Adjust based on your design */
    left: 50%; /* Center it relative to the icon */
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 4px 34px 4px;
    z-index: 2123;

    opacity: 0;
    pointer-events: none;
    transform: scale(0.95) translateX(-50%);
    transition: opacity 150ms, transform 150ms;
    transform-origin: 50% 50%;
}

.volumeSliderContainer.active {
    opacity: 1;
    pointer-events: all;
    transform: scale(1) translateX(-50%);
    transition: opacity 100ms, transform 100ms;
}


.volumeSliderInnerContainer {
    padding: 14px;
    background-color: var(--white);
    border-radius: var(--border-radius-mid);
    box-shadow: 0px 3px 12px rgba(0,0,0,0.08);
    border: 1px solid var(--lightGrey);
    border-radius: var(--border-radius-small);
}
