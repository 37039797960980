.StickerItem-sticker-item {
    margin-right: 16px;
    margin-bottom: 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.StickerItem-profile-picture {
    position: absolute;
    bottom: 0;
    right: 0;
    
}

.StickerItem-hover-box {
    position: absolute;
    bottom: 0;
    width: 56px;
    height: 64px;
    opacity: 0;
    display: none;
}
.StickerItem-sticker-item:hover .StickerItem-hover-box {
    display: initial;
}

.StickerItem-content {
    position: absolute;
    bottom: 56px;
    width: 220px;
    padding: 0px;
    background-color: var(--lightGrey);
    border-radius: var(--border-radius-small);
    box-shadow: var(--shadow-clickable);
    z-index: 10;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    transform: scale(0.95);
    opacity: 0;
    transition: opacity 150ms, transform 150ms;
    transform-origin: 50% 50%;
    pointer-events: none;
}

.StickerItem-sticker-item:hover .StickerItem-content {
    transform: scale(1);
    opacity: 1;
    transition: opacity 100ms, transform 100ms;
    pointer-events: all;
}

.StickerItem-padding {
    padding: 16px 8px;
}

.StickerItem-sticker-image {
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    min-height: 48px;
    max-height: 48px;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.StickerItem-sticker-image.mid {
    width: 64px;
    min-width: 64px;
    max-width: 64px;
    height: 64px;
    min-height: 64px;
    max-height: 64px;
}
.StickerItem-sticker-image.big {
    width: 72px;
    min-width: 72px;
    max-width: 72px;
    height: 72px;
    min-height: 72px;
    max-height: 72px;
}
.StickerItem-sticker-image.max {
    width: 144px;
    min-width: 144px;
    max-width: 144px;
    height: 144px;
    min-height: 144px;
    max-height: 144px;
}

.StickerItem-sticker-image.love-it {
    background-image: url(../../assets/images/stickers/love-it.png);
}
.StickerItem-sticker-image.pure-gold {
    background-image: url(../../assets/images/stickers/pure-gold.png);
}
.StickerItem-sticker-image.this-or-that {
    background-image: url(../../assets/images/stickers/this-or-that.png);
}
.StickerItem-sticker-image.steal-this {
    background-image: url(../../assets/images/stickers/steal-this.png);
}
.StickerItem-sticker-image.fresh {
    background-image: url(../../assets/images/stickers/fresh.png);
}
.StickerItem-sticker-image.save-for-later {
    background-image: url(../../assets/images/stickers/save-for-later.png);
}
.StickerItem-sticker-image.vibes {
    background-image: url(../../assets/images/stickers/vibes.png);
}
.StickerItem-sticker-image.cut-it {
    background-image: url(../../assets/images/stickers/cut-it.png);
}
.StickerItem-sticker-image.wip {
    background-image: url(../../assets/images/stickers/wip.png);
}
.StickerItem-sticker-image.take-a-peek {
    background-image: url(../../assets/images/stickers/take-a-peek.png);
}
.StickerItem-sticker-image.thoughts {
    background-image: url(../../assets/images/stickers/thoughts.png);
}
.StickerItem-sticker-image.keep-exploring {
    background-image: url(../../assets/images/stickers/keep-exploring.png);
}
.StickerItem-sticker-image.winner {
    background-image: url(../../assets/images/stickers/winner.png);
}
.StickerItem-sticker-image.dope {
    background-image: url(../../assets/images/stickers/dope.png);
}
.StickerItem-sticker-image.ok {
    background-image: url(../../assets/images/stickers/ok.png);
}
.StickerItem-sticker-image.not-sure {
    background-image: url(../../assets/images/stickers/not-sure.png);
}
.StickerItem-sticker-image.killed-it {
    background-image: url(../../assets/images/stickers/killed-it.png);
}
.StickerItem-sticker-image.idea {
    background-image: url(../../assets/images/stickers/idea.png);
}
.StickerItem-sticker-image.check-the-deets {
    background-image: url(../../assets/images/stickers/check-the-deets.png);
}
.StickerItem-sticker-image.hot {
    background-image: url(../../assets/images/stickers/hot.png);
}


.StickerItem-count-wrapper {
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background-color: var(--lightGrey);
    display: flex;
    align-items: center;
    justify-content: center;
}

.StickerItem-count-text {
    font-size: 10px;
    color: var(--black);
    text-align: center;
}

.StickerItem-count-overlay {
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    border: 1px solid rgba(255,255,255,0.20);
}

.StickerItem-sticker-divider {
    height: 1px;
    width: 100%;
    background-color: var(--lightGrey2);
}

.StickerItem-sticker-btn {
    box-sizing: border-box;
    width: 100%;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: var(--border-radius-small);
    border-bottom-right-radius: var(--border-radius-small);
    cursor: pointer;
    background-color: transparent;
    transition: background-color 150ms;
}
.StickerItem-sticker-btn:hover {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.StickerItem-sticker-btn:active {
    background-color: var(--lightGrey3);
    transition: background-color 0ms;
}
