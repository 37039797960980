.pinned {
    width: 30px;
    height: 30px;
    background: var(--black);
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -8px;
    right: 0px;
}

.clickableP > i, .clickableP > p {
    color: var(--black);
    transition: color 200ms;
}
.clickableP:hover > i, .clickableP:hover > p {
    color: var(--blue);
}

.addFavoriteAlbum {
    flex: 1 1;
    box-shadow: 0px 1px 0px var(--lightGrey) inset, 
                0px -1px 0px var(--lightGrey) inset, 
                1px 0px 0px var(--lightGrey) inset, 
                -1px 0px 0px var(--lightGrey) inset;
    border-radius: var(--border-radius-mini);
    height: calc(100% - 52px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: box-shadow 250ms;
}
.addFavoriteAlbum:hover {
    box-shadow: 0px 3px 0px var(--blue) inset, 
                0px -3px 0px var(--blue) inset, 
                3px 0px 0px var(--blue) inset, 
                -3px 0px 0px var(--blue) inset;
}

.addFavoriteAlbum .favoriteAlbumIcon {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.addFavoriteAlbum:hover .favoriteAlbumIcon {
    color: var(--black);
}

.changeBanner {
    position: absolute;
    right: 16px;
    bottom: 16px;
}

.suggestedUsersContent {
    border-radius: var(--border-radius-mid);
    background-color: var(--lightestGrey);
    padding: 32px;
    border: 1px solid var(--lightGrey);
}

@media (max-width: 768px) {
    .suggestedUsersContent {
        padding: 24px;
    }
}