.maximize {
    color: var(--highDarkGrey);
    cursor: pointer;
    transition: color 200ms;
}
.maximize:hover {
    color: var(--black);
}

.title {
    color: var(--black);
    transition: color 200ms;
}
.title:hover {
    color: var(--blue);
}

.artistLink {
    color: var(--highDarkGrey);
}
.artistLink:hover {
    text-decoration: underline;
}

.ratingContainer {
    margin-top: 5px;
    position: relative;
}

.clearRatingBtn {
    position: absolute;
    right: -36px;
    transform: translateX(-20px);
    opacity: 0;
    pointer-events: none;
    transition: transform 200ms, opacity 200ms;
    padding: 4px 8px;
    cursor: pointer;
}
.clearRatingBtn .clearRatingIcon {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.clearRatingBtn:hover .clearRatingIcon {
    color: var(--black);
    transition: color 100ms;
}
.ratingContainer:hover .clearRatingBtn.active {
    transform: translateX(0px);
    opacity: 1;
    pointer-events: all;
}