/* FOOTER */
/* --------------------------------------------- */

.container {
    /* background: var(--white); */
    flex: 1;
    margin-top: 96px;
    border-top: 1px solid var(--lightGrey);
}
:global(.light) .container {
    background: var(--white);
    border-top: none;
}

.footer {
    padding: 64px 40px;
    max-width: calc(1200px - 40px);
    margin: auto;
    color: var(--black);
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
 
.footer p {
    color: var(--highDarkGrey);
    font-size: 14px;
    line-height: 27px;
}

.footer .footerSection p {
    font-weight: 500;
}

.footer a {
    text-decoration: none;
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.footer a.pro {
    text-decoration: none;
    color: var(--yellow);
    transition: color 200ms;
}
.footer a:hover {
    color: var(--blue);
}


.footer .legal p {
    font-size: 13px;
    color: var(--highDarkGrey);
}
.footer .legal .right {
    text-align: right;
}
.footer .legal a {
    color: var(--highDarkGrey);
}
.footer .legal a:hover {
    color: var(--blue);
}
.footer .legal .dot {
    letter-spacing: 5px;
    color: var(--highDarkGrey);
}

.footer .legal .social {
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 1000px;
    background-color: var(--lightGrey);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 4px;
    transition: background-color 300ms;
}
.footer .legal .social:hover {
    background-color: var(--darkLightGrey);
}
.footer .legal .social .twitter {
    mask-image: url(../../assets/icons/x.svg);
    width: 17px;
    height: 17px;
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: var(--black);
}
.footer .legal .social .instagram {
    mask-image: url(../../assets/images/instagram.svg);
    width: 16px;
    height: 16px;
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: var(--black);
}


@media(max-width: 1100px) {

}

@media(max-width: 960px) {
    .row {
        flex-direction: column;
    }   
    .footerSection {
        margin-top: 16px;
    } 
    .footerSection:first-child {
        margin-top: 0px;
    } 

}

@media(max-width: 768px) {
    .container {
        margin-top: 64px;
    }
}