.menuItem .menuItemText {
    color: var(--black);
    font-size: 14px;
    white-space: nowrap;
}
.menuItem:hover .menuItemText {
    color: var(--black);
}

.menuItemIconContainer {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    background-color: var(--lightGrey);
}

.menuItem .menuItemIcon {
    color: var(--black);
}

.menuItem {
    background-color: transparent;
    transition: background-color 150ms;
    padding: 12px;
    border-radius: var(--border-radius-small);
}
.menuItem:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.menuItem:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}

.icon {
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 8px;
}
.crown {
    background-image: url(../../../../assets/images/crown.svg);
}
.fire {
    background-image: url(../../../../assets/images/fire.svg);
}
.trend {
    background-image: url(../../../../assets/images/trend.svg);
}