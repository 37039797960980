.a {
    color: var(--black);
    transition: color 200ms;
}
.a:hover {
    color: var(--blue);
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.alignCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.disabledShare {
    pointer-events: none;
    opacity: 0.5;
}
.disabledLink {
    pointer-events: none;
    opacity: 0.6;
}

.translateBtn {
    cursor: pointer;
    transition: color 200ms;
    color: var(--blue);
}
.translateBtn:hover {
    color: var(--blue_050);
}
