.container {
    background-color: var(--white);
    border-bottom: 1px solid var(--lightGrey);
    box-shadow:  var(--shadow-light);
}
:global(.light) .container {
    border-bottom: none;
}

.sticky {
    position: sticky;
    top: 56px;
    z-index: 1030;
    overflow: hidden;
}

.tab {
    border-radius: var(--border-radius-small);
    padding: 12px 24px;
    background-color: transparent;
    transition: background-color 150ms;
    cursor: pointer;

    display: flex;
    align-items: center;
    margin: 4px;
}
.tab:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.tab:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.tab.active {
    background-color: transparent;
    transition: background-color 150ms;
}

.tab .tabText, .tab .tabIcon {
    color: var(--black);
    transition: color 200ms;
}
.tab.active .tabText, .tab.active .tabIcon {
    color: var(--blue);
}

.tab.hidden {
    opacity: 0;
    display: none;
}

.tabIcon {
    transform: rotate(0deg);
    transition: color 200ms, transform 250ms !important;
}
.tabIcon.moreActive {
    transform: rotate(180deg);
}

.activeIndicator {
    height: 4px;
    width: 100%;
    background-color: var(--blue);
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
}
.disabledIndicator {
    height: 4px;
    width: 100%;
}

.moreDropdown {
    padding: 12px !important;
}

.moreTab {
    padding: 12px;
    width: 200px;
    background-color: transparent;
    transition: background-color 200ms;
    border-radius: var(--border-radius-small);
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media(max-width: 630px) {
    .moreTab {
        width: 116px;
    }
}

.moreTab:hover {
    background-color: var(--lightGrey);
}
.moreTab.active {
    background-color: transparent;
}

.moreTab .moreTabText {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.moreTab.active .moreTabText {
    color: var(--blue);
}

.moreTab .moreTabIcon {
    opacity: 0;
    color: var(--blue);
}
.moreTab.active .moreTabIcon {
    opacity: 1;
}

.scrolledComponent {
    width: 0;
    max-width: 0;
    height: 0;
    max-height: 0;
    margin-right: 0;
    opacity: 0;
    transform: translateY(56px);
    transition: opacity 200ms, transform 200ms;
}
.scrolledComponent.visible {
    width: initial;
    max-width: initial;
    height: initial;
    max-height: initial;
    margin-right: 16px;
    opacity: 1;
    transform: translateY(0px);
}