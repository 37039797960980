/* CONTAINER */
.container {
    padding: 16px;
    border-bottom: 1px solid var(--lightGrey);
    background-color: transparent;
    transition: background-color 200ms;
}
.container.lighterStyle {
    border-bottom: 1px solid var(--darkLightGrey);
}

:global(.light) .container.lighterStyle {
    border-bottom: 1px solid var(--lightGrey);
}

.container:hover {
    background-color: var(--hoverColor);
    transition: background-color 0ms;
}
.container:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.container.lighterStyle:hover {
    background-color: var(--darkLightGrey);
}
:global(.light) .container.lighterStyle:hover {
    background-color: var(--lightGrey);
}

.container.last {
    border-bottom: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.container.first {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

/* HOVERS */
.a {
    color: var(--black);
    transition: color 200ms;
}
.a:hover {
    color: var(--blue);
}

.creator {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.creator:hover {
    color: var(--black);
}

/* ICON AND COUNTS */
.container .icon {
    color: var(--highDarkGrey);
    transition: color 200ms;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: -6px;
    margin-bottom: -6px;
}
:global(.light) .container .icon {
    color: var(--grey);
}
:global(.light) .container .icon.counts {
    color: var(--highDarkGrey);
}
.container .icon:hover {
    color: var(--black);
    transition: color 0ms;
}
:global(.light) .container .icon.counts:hover {
    color: var(--black);
    transition: color 0ms;
}
.container .icon.active {
    color: var(--red);
}

/* CONTENT RATING */
.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.alignCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.disabledLink {
    pointer-events: none !important;
    opacity: 0.6 !important;
}

.artistLink {
    color: var(--highDarkGrey);
}
.artistLink:hover {
    text-decoration: underline;
}


.featuredBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: -1px;
}
.featuredBox > div {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: var(--border-radius-small);
    background-color: rgba(255, 45, 85, 0.15); 
}
.featuredBox > div > h6 {
    font-size: 12px; 
    color: rgba(255, 45, 85, 1);
}

.image {
    display: block;
    width: 100%;
    border-radius: 16px;
    max-height: 330px;
    object-fit: cover;
    object-position: center;
}
