.flex {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.outerContainer {
	position: fixed;
	width: 100%;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--white);
	z-index: 2120;
	top: 0px;
	border-bottom: 1px solid var(--lightGrey);
	box-shadow: var(--shadow-light);
}
:global(.light) .outerContainer {
	border-bottom: 1px solid var(--white);
}

.container {
	max-width: 1200px;
	padding: 0 16px;
	flex: 1 1;
	display: flex;
	align-items: center;
}

.disabled {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: var(--white060);
	z-index: 2001;
}

.menuBg {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	background: black;
	z-index: 1112;
	transition: opacity 200ms;
}

.logo {
	background-image: url(../../assets/images/social-icon.png);
	background-position: left center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 40px;
	width: 40px;
	border-radius: 1000px;
	background-color: var(--deepBlue);
}


.searchPreview {
	height: 38px;
	border-radius: 1000px;
	background-color: var(--lightestGrey);
	padding-left: 16px;
	padding-right: 64px;
	cursor: text;
}


@keyframes pulse {
	0% {
		transform: scale(1.0);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1.0);
	}
}

.newVersion {
	position: absolute;
	right: -4px;
	top: -2px;
	background-color: var(--red);
	font-size: 10px;
	color: white;
	padding: 1px 6px 2px 5px;
	line-height: 12px;
	border-radius: 100px;
	font-weight: 600;
	cursor: pointer;
	animation: pulse 600ms ease-in-out 5 forwards;
	pointer-events: none;
}

.dot {
    width: 9px;
    height: 9px;
    border-radius: 1000px;
    background-color: var(--red);
    position: absolute;
    top: 0;
    right: 0;
}

.proShapeWrapper {
	pointer-events: none;
	position: absolute;
	height: 100%;
	width: 100%;
	overflow: hidden;
	border-radius: var(--border-radius-small);
	display: flex;
	align-items: center;
	justify-content: center;
}

@keyframes leftToRightMetalSwipe {
	0% {
		transform: translateX(-800%) rotate(20deg);
	}
	50% {
		transform: translateX(-800%) rotate(20deg);
	}
	100% {
		transform: translateX(400%) rotate(20deg);
	}
}

.proShape {
	width: 24px;
	height: 80px;
	position: absolute;
	background: linear-gradient(var(--blue025), var(--blue012));
	animation: leftToRightMetalSwipe 3s infinite cubic-bezier(.08,.96,.39,1);
}
