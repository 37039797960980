.myRatingContainer {
    position: relative;
}

.myRating {
    height: 51px;
    background-color: var(--lightGrey);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-small);
}

:global(.light) .myRating {
    background-color: var(--lightestGrey);
}

.myRatingOverlayBtn {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
}

.myRatingContainer:hover .myRatingOverlayBtn {
    opacity: 0;
}

.a h5 {
    color: var(--black);
    transition: color 200ms;
}
.a:hover h5 {
    color: var(--blue);
}

.biographyWrapper {
    overflow: hidden;
    position: relative;
    height: 100px;
    width: 100%;
}
.biographyFade {
    position: absolute;
    width: 100%;
    bottom: 0px;
    height: 40px;
    background: linear-gradient(var(--whiteTransparent), var(--white));
}

.biographyBtn {
    margin-right: -36px;
    margin-bottom: -12px;
    padding: 12px;
    padding-right: 48px;
    background: radial-gradient(var(--white), var(--whiteTransparent));
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.biographyBtnText {
    cursor: pointer;
    color: var(--black);
    transition: color 200ms;
}
.biographyBtnText:hover {
    color: var(--blue);
    transition: color 0ms;
}

.biographyLoading {
    border-radius: var(--border-radius-mid);
    overflow: hidden;
    position: relative;
    height: 100px;
    background: var(--lightestGrey);
    flex: 1 1;
}

@keyframes fadeInLoadingInformation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.loadingInformation {
    color: var(--grey);
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    position: absolute;
    width: 100%;
    max-width: 490px;
    transform: translateY(104px);
    animation: fadeInLoadingInformation 130ms 1 forwards;
}
