.dropdown {
    position: absolute;
    padding: 7px;
    background-color: var(--white);
    border-radius: var(--border-radius-mid);
    box-shadow: var(--shadow-large-dark);
    border: 1px solid var(--lightGrey);

    pointer-events: none;
    opacity: 0;
    transform: perspective(30px)  rotateX(-1deg) translateY(10px);
    transition: opacity 150ms, transform 150ms;
    transform-origin: 50% 0%;
}
:global(.light) .dropdown {
    border: none;
    padding: 8px;
}
.dropdown.disabled * {
    pointer-events: none !important;
}

.dropdown.visible {
    pointer-events: initial;
    opacity: 1;
    transform: perspective(30px) rotateX(0deg) translateY(0px);
}

.dropdown.right {
    right: 0px;
}
.dropdown.left {
    left: 0px;
}

.dropdown.dropdownSimple {
    transform: scale(0.95);
    transition: opacity 150ms, transform 150ms;
    transform-origin: 50% 50%;
}

.dropdown.dropdownSimple.visible {
    transform: scale(1);
    transition: opacity 100ms, transform 100ms;
}