/* Menu & BG */
@keyframes mobilesearchmenuOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.wrapper {
    position: fixed;
    top: 57px;
    left: 0;
    width: 100%;
    height: calc(100vh - 57px);

    background-color: rgba(0,0,0,0.5);

    display: flex;
    justify-content: flex-start;

    animation: mobilesearchmenuOpacity 200ms ease-out 0ms 1 forwards;
    z-index: 2130;
}

.background {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Menu */
@keyframes mobilesearchmenuTransform {
    0% {
        transform: translateX(-400px);
    }
    100% {
        transform: translateX(0px);
    }
}

.menu {
    box-sizing: border-box;
    border-right: 1px solid var(--lightGrey);
    background-color: var(--white);
    width: 100%;
    max-width: 500px;
    height: 100%;

    animation: mobilesearchmenuTransform 200ms ease-out 0ms 1 forwards;

    display: flex;
    flex-direction: column;
}

.menuScroller {
    flex: 1 1;
    overflow-y: scroll;
    padding-bottom: 120px;
}

/* Item list */
.mobilesearchmenu-menu-item-wrapper .mobilesearchmenu-menu-item-list {
    background-color: var(--white);
    max-height: 0px;
    overflow: hidden;
    transition: max-height 200ms;
}

.mobilesearchmenu-menu-item-wrapper.mobilesearchmenu-menu-item-active .mobilesearchmenu-menu-item-list {
    max-height: 324px;
}

/* Item arrow */
.mobilesearchmenu-menu-item-wrapper .mobilesearchmenu-menu-item-arrow {
    transform: rotateZ(0deg);
    transition: transform 200ms;
}

.mobilesearchmenu-menu-item-wrapper.mobilesearchmenu-menu-item-active .mobilesearchmenu-menu-item-arrow {
    transform: rotateZ(180deg);
}

/* Socials */
.mobilesearchmenu-menu-socials {
    background-color: var(--red);
    border-top: 1px solid var(--lightGrey);
    padding-bottom: 48px;
}

.menuSocial {
    padding: 8px;
}
.menuSocial.twitter:hover {
    color: var(--twitter);
}
.menuSocial.tnstagram:hover {
    color: var(--instagram);
}

/* Light mode fix */
.light-mode .mobilesearchmenu-menu-item-wrapper .mobilesearchmenu-menu-item-list {
    background-color: var(--lightGrey);
    box-shadow: 0px 1px 0px var(--lightGrey2) inset, 
                0px -1px 0px var(--lightGrey2) inset;
}

/* Mobile fix */
@media(max-width: 490px) {
    .mobilesearchmenu-wrapper .background-clickable {
        background-color: transparent !important;
    }   
}

/* Blue design fix */
.navbar-wrapper.navbar-blue-design .mobilesearchmenu-wrapper .link {
    color: var(--black) !important;
}
.navbar-wrapper.navbar-blue-design .mobilesearchmenu-wrapper .link:hover {
    opacity: 0.85;
}

/* Search text input */
.mobilesearchmenu-textinput-input {
    width: calc(100% - 68px) !important;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--lightGrey2);
    box-shadow: none !important;
    background-color: transparent !important;
    padding: 24px 16px !important;
    padding-left: 52px !important;
}

/* Tab picker */
.tabPickerItem {
    padding: 20px 0px;
    cursor: pointer;
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.tabPickerItem:hover, .tabPickerItem.active {
    color: var(--black);
    transition: color 100ms;
}

.tabPickerLine {
    box-shadow: border-box;
    width: calc(100% / 3 - 16px);
    margin: 0px 8px;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--blue);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    transition: left 200ms;
}

/* input */
.inputContainer {
    margin: 16px 16px 8px 16px;
    height: 38px;
    border-radius: var(--border-radius-small);
    background-color: var(--lightestGrey);
    flex-direction: row;
    display: flex;
    align-items: center;
    position: relative;
    cursor: text;
    box-shadow: var(--shadow-input);
    transition: box-shadow 200ms;
    z-index: 2334;
}
.inputContainer:hover {
    box-shadow: var(--shadow-input-hover);
}
.inputContainer.active {
    box-shadow: var(--shadow-input-active);
}

.input {
    cursor: text;
    padding-left: 43px;
    padding-right: 24px;
    padding-bottom: 1px;
    border: none;
    outline: none;
    background: none;
    height: 37px;
    flex: 1 1;
    font-size: 14px;
    color: var(--black);
    width: 270px;
}
.input::placeholder {
    color: var(--highDarkGrey);
}

.icon {
    position: absolute;
    left: 14px;
    pointer-events: none;
}

.clearTooltip {
    width: 110px;
}


.searchMore {
    border-radius: var(--border-radius-small);
    cursor: pointer;
    padding: 12px 8px;
    background-color: transparent;
    transition: background-color 150ms;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.searchMore:hover, .searchMore.active {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.searchMore:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}

.searchMore p {
    font-weight: 500;
    color: var(--black) !important;
}
.searchMore.large p {
    font-size: 16px;
    font-weight: 600;
    color: var(--black) !important;
}

.searchMore .searchMoreIconContainer {
    width: 48px;
    height: 48px;
    border-radius: 100px;
    background-color: var(--lightGrey);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    transition: background-color 200ms;
}
:global(.light) .searchMore .searchMoreIconContainer {
    background-color: var(--lightestGrey);
}
.searchMore:hover .searchMoreIconContainer, .searchMore.active .searchMoreIconContainer {
    background-color: var(--darkLightGrey050);
    transition: background-color 0ms;
}

:global(.light) .searchMore:hover .searchMoreIconContainer,  :global(.light) .searchMore.active .searchMoreIconContainer {
    background-color: var(--lightGrey050);
}