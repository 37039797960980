.divider {
    width: 1px;
    background-color: var(--darkLightGrey);
    height: 80px;
}
@media(max-width: 630px) {
    .divider {
        height: 64px;
    }
}

.col {
    justify-content: center;
    display: flex;
    align-items: stretch;
    flex: 1 1;
}
.colColumn {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1 1;
}

.col.active h4 {
    color: var(--black);
    transition: color 200ms;
}
.col.active:hover h4 {
    color: var(--blue);
}