.container {
    padding: 8px;
    border-radius: var(--border-radius-small);
    background-color: transparent;
    transition: background-color 150ms;
}
.container:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.container:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}

.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.alignCenter {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.title {
    font-size: 16px;
    color: var(--black);
    transition: color 250ms;
}