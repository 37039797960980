.div {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
}

.input {
    flex: 1 1;
    outline: none;
    resize: none;
    border: none;
    padding: 15.5px 16px;
    border-radius: var(--border-radius-small);
    font-size: 14px;
    color: var(--black);
    
    background-color: var(--white);
    box-shadow: var(--shadow-input);
    transition: box-shadow 200ms, background-color 200ms;

    -webkit-appearance: none;

}
.input:hover {
    box-shadow: var(--shadow-input-hover);
}
.input:focus {
    box-shadow: var(--shadow-input-active);
}

/* OTHER */
.input::placeholder {
    color: var(--highDarkGrey);
}

.textAreaMaxLength {
    color: var(--highDarkGrey);
    text-align: right;
}

.textAreaIcon {
    color: var(--highDarkGrey);
    position: absolute;
    bottom: 8px;
    right: 8px;
}

.inputMaxLength {
    color: var(--highDarkGrey);
    text-align: right;
}

.proText {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.proText:hover {
    color: var(--black);
}
.pro {
    padding: 1px 5px 2px 5px;
    border-radius: 6px;
    background-color: var(--yellow);
    color: white;
}

.icon {
    position: absolute;
    right: 8px;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.placeholderCarousel {
    position: absolute;
    width: calc(100% - 24px - 1px);
    height: calc(100% - 20px);
    top: 0;
    left: 0;
    pointer-events: none;
    color: var(--highDarkGrey);
    padding: 10px 12px;
    margin-left: 1px;
    animation: fade 6000ms  linear infinite forwards;
}
.placeholderCarousel.textArea {
    height: calc(100% - 18px);
    padding: 9px 12px;
}