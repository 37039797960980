.exclamation {
    width: 46px; 
    height: 46px; 
    background-color: var(--lightGrey); 
    border-radius: 1000px;
    align-items: center; 
    justify-content: center;
}

.a {
    color: var(--black);
    transition: color 200ms;
}
.a:hover {
    color: var(--blue);
}

.container {
    padding: 8px;
    background-color: transparent;
    border-radius: var(--border-radius-small);
    transition: background-color 150ms;
    cursor: pointer;
}
.container:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.container:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}


.sticker {
    margin: 2px;
    width: 46px;
    min-width: 46px;
    max-width: 46px;
    height: 46px;
    min-height: 46px;
    max-height: 46px;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}
.sticker.love-it {
    background-image: url(../../../../../../assets/images/stickers/love-it.png);
}
.sticker.pure-gold {
    background-image: url(../../../../../../assets/images/stickers/pure-gold.png);
}
.sticker.this-or-that {
    background-image: url(../../../../../../assets/images/stickers/this-or-that.png);
}
.sticker.steal-this {
    background-image: url(../../../../../../assets/images/stickers/steal-this.png);
}
.sticker.fresh {
    background-image: url(../../../../../../assets/images/stickers/fresh.png);
}
.sticker.save-for-later {
    background-image: url(../../../../../../assets/images/stickers/save-for-later.png);
}
.sticker.vibes {
    background-image: url(../../../../../../assets/images/stickers/vibes.png);
}
.sticker.cut-it {
    background-image: url(../../../../../../assets/images/stickers/cut-it.png);
}
.sticker.wip {
    background-image: url(../../../../../../assets/images/stickers/wip.png);
}
.sticker.take-a-peek {
    background-image: url(../../../../../../assets/images/stickers/take-a-peek.png);
}
.sticker.thoughts {
    background-image: url(../../../../../../assets/images/stickers/thoughts.png);
}
.sticker.keep-exploring {
    background-image: url(../../../../../../assets/images/stickers/keep-exploring.png);
}
.sticker.winner {
    background-image: url(../../../../../../assets/images/stickers/winner.png);
}
.sticker.dope {
    background-image: url(../../../../../../assets/images/stickers/dope.png);
}
.sticker.ok {
    background-image: url(../../../../../../assets/images/stickers/ok.png);
}
.sticker.not-sure {
    background-image: url(../../../../../../assets/images/stickers/not-sure.png);
}
.sticker.killed-it {
    background-image: url(../../../../../../assets/images/stickers/killed-it.png);
}
.sticker.idea {
    background-image: url(../../../../../../assets/images/stickers/idea.png);
}
.sticker.check-the-deets {
    background-image: url(../../../../../../assets/images/stickers/check-the-deets.png);
}
.sticker.hot {
    background-image: url(../../../../../../assets/images/stickers/hot.png);
}

.threadBox {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    border-radius: var(--border-radius-small);
    box-shadow: var(--shadow-clickable);
    transition: box-shadow 200ms;
}
.threadBox:hover {
    box-shadow: var(--shadow-clickable-active);
}
.threadBox:active {
    box-shadow: var(--shadow-clickable-active);
}