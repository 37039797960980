/* CENTER */
.overlay {
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	background-color: rgba(0,0,0,0.6);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 4015;
	overflow: auto;
	place-items: center;
}

.content {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 48px;
	margin-bottom: 80px;
	transform: translateZ(0);
	min-height: calc(100vh - 48px - 80px);
	width: 100%;
}

/* POPUP */
@keyframes scale-in {
	0% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1.0);
	}
}
.popup {
	background-color: var(--lightGrey);
	border-radius: var(--border-radius-mid);
	box-shadow: 0px 8px 32px rgba(0,0,0,0.25);
	width: 100%;
	max-width: 600px;
	animation: scale-in 100ms ease-out forwards;
	position: relative;
}
:global(.light) .popup {
	background-color: var(--lightestGrey);
}
.popup.smallWidth {
	max-width: 500px;
}
.popup.midWidth {
	max-width: 650px;
}
.popup.midLargeWidth {
	max-width: 750px;
}
.popup.midLargeSecondWidth {
	max-width: 844px;
}
.popup.largeWidth {
	max-width: 1032px;
}

.innerContent {
	padding: 16px;
}
.innerContent.smallPadding {
	padding: 8px;
}
.innerContent.largePadding {
	padding: 32px;
}
.innerContent.noPadding {
	padding: 0px;
}

@media(max-width: 630px) {
	.innerContent.largePadding {
		padding: 16px;
	}
}

/* HEADER */
.header {
	border-top-left-radius: var(--border-radius-mid);
	border-top-right-radius: var(--border-radius-mid);
	padding: 8px;
	background-color: var(--lightGrey);
	border-bottom: 1px solid var(--darkLightGrey);
	box-shadow: none;
}
:global(.light) .header {
	background-color: var(--white);
	box-shadow: var(--shadow-light);
	border-bottom: none;
}

/* HELP */
.help {
	position: absolute;
	right: -63px;
	bottom: 0px;
	background-color: var(--lightGrey);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 8px 12px;
	border-radius: var(--border-radius-mid);
	box-shadow: 0px 8px 32px rgba(0,0,0,0.25);
	cursor: pointer;
	transition: background-color 200ms;
}
:global(.light) .help {
	background-color: var(--lightestGrey);
}
.help:hover {
	background-color: var(--darkLightGrey);
	transition: background-color 0ms;
}
:global(.light) .help:hover {
	background-color: var(--lightGrey);
}