@keyframes highlight {
    0% {
        background-color: var(--lightGrey);
    }
    66% {
        background-color: var(--lightGrey);
    }
    100% {
        background-color: none;
    }
}
.container.highlight {
    animation: highlight 3s ease-out 0ms 1 forwards;
}
@keyframes highlight-light {
    0% {
        background-color: var(--lightGrey);
    }
    66% {
        background-color: var(--lightGrey);
    }
    100% {
        background-color: none;
    }
}
.container.light.highlight {
    animation: highlight-light 3s ease-out 0ms 1 forwards;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    height: 60px;
    border-radius: var(--border-radius-small);
    background-color: transparent;
    transition: background-color 150ms;
}
.container:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.container:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}

.container.hoveringMenu {
    background-color: transparent !important;
    transition: background-color 200ms !important;
}

.itemContainer {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ratingContainer {
    width: 56px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* HOVERS */
.container .more {
    opacity: 0;
    color: var(--highDarkGrey);
    transition: opacity 200ms, color 200ms;
}
.container:hover .more {
    opacity: 1;
}

.container div:hover .more {
    color: var(--black);
}

.container div .more.visible {
    color: var(--black);
    opacity: 1;
}

/* TRACK TOOLTIP MENU */
.menuContainer {
    padding: 0 !important;
    cursor: default;
}

.menuItem {
    background-color: transparent;
    transition: background-color 200ms;
    padding: 12px;
    padding-right: 24px;
    border-radius: var(--border-radius-small);
}
.menuItem:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}

.menuItem .menuItemText {
    color: var(--black) !important;
}

.artistLink {
    text-decoration: none;
    color: var(--highDarkGrey) !important;
}
.artistLink:hover {
    text-decoration: underline;
}

.hidden {
    opacity: 0;
}