.btn {
    height: 45px;
    border-radius: var(--border-radius-small);
    cursor: pointer;

    background-color: var(--primary);
    box-shadow: var(--static-button-shadow);
                
    transition: background-color 150ms;
}

.margin {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 24px;
}

.text {
    color: white;
}

:global(.teal) .text {
    color: var(--lightestGrey);
}

/* Primary */
.btn:hover {
    background-color: var(--primary090);
    transition: background-color 0ms;
}
.btn:active {
    background-color: var(--primary075);
    transition: background-color 0ms;
}
.btn.disabled {
    opacity: 0.7;
    background-color: var(--primary) !important;
    cursor: default;
}

/* Secondary */
.btn.secondary {
    background-color: var(--lightGrey);
    transition: background-color 150ms;
    box-shadow: var(--static-button-shadow-secondary);
}
.btn.secondary .text {
    color: var(--black);
}
.btn.secondary:hover {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.btn.secondary:active {
    background-color: var(--lightGrey3);
    transition: background-color 0ms;
}
.btn.secondary.disabled {
    background-color: var(--lightGrey) !important;
}


/* Secondary 2 */
.btn.secondaryTwo {
    background-color: var(--lightGrey2);
    transition: background-color 150ms;
    box-shadow: var(--static-button-shadow-secondary);
}
.btn.secondaryTwo .text {
    color: var(--black);
}
.btn.secondaryTwo:hover {
    background-color: var(--lightGrey3);
    transition: background-color 0ms;
}
.btn.secondaryTwo:active {
    background-color: var(--lightGrey3);
    transition: background-color 0ms;
}
.btn.secondaryTwo.disabled {
    background-color: var(--lightGrey2) !important;
}

/* Transparent */
.btn.transparent {
    background-color: transparent;
    transition: background-color 150ms;
    box-shadow: none;
}
.btn.transparent .text {
    color: var(--black);
}
.btn.transparent:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.btn.transparent:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.btn.transparent.disabled {
    background-color: transparent !important;
}

/* Transparent */
.btn.transparentBright {
    background-color: transparent;
    transition: background-color 150ms;
    box-shadow: none;
}
.btn.transparentBright .text {
    color: var(--black);
}
.btn.transparentBright:hover {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.btn.transparentBright:active {
    background-color: var(--lightGrey3);
    transition: background-color 0ms;
}
.btn.transparentBright.disabled {
    background-color: transparent !important;
}

/* Red */
.btn.destructive {
    background-color: var(--red);
    transition: background-color 150ms;
}
.btn.destructive:hover {
    background-color: var(--red090);
    transition: background-color 0ms;
}
.btn.destructive:active {
    background-color: var(--red075);
    transition: background-color 0ms;
}
.btn.destructive.disabled {
    background-color: var(--red) !important;
}

/* Yellow */
.btn.yellow {
    background-color: var(--yellow);
    transition: background-color 150ms;
}
.btn.yellow:hover {
    background-color: var(--yellow090);
    transition: background-color 0ms;
}
.btn.yellow:active {
    background-color: var(--yellow075);
    transition: background-color 0ms;
}
.btn.yellow.disabled {
    background-color: var(--yellow) !important;
}

/* Green */
.btn.green {
    background-color: var(--green);
    transition: background-color 150ms;
}
.btn.green:hover {
    background-color: var(--green090);
    transition: background-color 0ms;
}
.btn.green:active {
    background-color: var(--green075);
    transition: background-color 0ms;
}
.btn.green.disabled {
    background-color: var(--green) !important;
}

/* Black */
.btn.black {
    background-color: var(--black);
    transition: background-color 150ms;
}
.btn.black .text {
    color: var(--lightestGrey);
}
.btn.black:hover {
    background-color: var(--black090);
    transition: background-color 0ms;
}
.btn.black:active {
    background-color: var(--black075);
    transition: background-color 0ms;
}
.btn.black.disabled {
    background-color: var(--black) !important;
}
