.container {
    padding-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.container.showLine {
    border-bottom: 1px solid var(--lightGrey);
}
/*
@media(max-width: 630px) {
    .container {
        margin-left: 16px;
        margin-right: 16px;
    }
}
*/

.text {
    font-size: 20px;
    color: var(--black);
    transition: color 200ms;
}
.subText {
    transition: color 200ms;
}
.subText.hover {
    transition: color 200ms;
}

.a:hover .text, .a:hover .text .subText {
    color: var(--blue);
}

.text .subText.hover:hover {
    color: var(--blue);
}

/* ANIMATION FOR ARROW */
@keyframes spring {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(2px);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes spring-left {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-2px);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes spring-up {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-2px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes spring-down {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(0px);
    }
}

.animation {
    transition: color 200ms;
}

.a:hover .animation {
    color: var(--blue);
    animation: spring 300ms cubic-bezier(.5,0,.51,.99);
}
.a:hover .animation.left {
    color: var(--blue);
    animation: spring-left 300ms cubic-bezier(.5,0,.51,.99);
}
.a:hover .animation.up {
    color: var(--blue);
    animation: spring-up 300ms cubic-bezier(.5,0,.51,.99);
}
.a:hover .animation.down {
    color: var(--blue);
    animation: spring-down 300ms cubic-bezier(.5,0,.51,.99);
}