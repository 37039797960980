.container {
    border-top: 1px solid var(--darkLightGrey050);
}

:global(.light) .container {
    border-top: 1px solid var(--lightGrey);
}

.link {
    color: var(--blue);
    text-decoration: underline;
    text-decoration-color: var(--blue050);
    transition: text-decoration-color 200ms;
    cursor: pointer;
}
.link:hover {
    text-decoration-color: var(--blue090);
    transition: text-decoration-color 130ms;
}