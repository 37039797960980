/* CONTAINER */
.container {
    padding: 8px;
    border-radius: var(--border-radius-small);
    background-color: transparent;
    transition: background-color 150ms;
}
.container:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.container:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}

:global(.delete-wrapper):hover .container {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}

/* ALIGNMENT */
.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.alignCenter {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

/* INFORMATION */
.title {
    font-size: 16px;
    color: var(--black);
    transition: color 250ms;
}

.artist {
    font-size: 14px;
    color: var(--highDarkGrey);
    margin-top: 4px;
}

.username {
    font-size: 14px;
    color: var(--highDarkGrey);
    text-align: center;
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* RATING */
.ratingContainer {
    width: 56px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 8px;
}

@media(max-width: 490px) {
    .title {
        font-size: 15px;
    }
    
    .artist {
        font-size: 13px;
    }
}

.artistLink {
    color: var(--highDarkGrey);
}
.artistLink:hover {
    text-decoration: underline;
}
