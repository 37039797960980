.container {
    padding: 24px; 
    padding-left: 32px;
    padding-right: 32px;
}

.linkSpan {
    cursor: pointer;
    color: var(--black);
    text-decoration: underline;
    text-decoration-color: var(--black050);
    font-weight: 500;
    transition: text-decoration-color 200ms;
}
.linkSpan:hover {
    text-decoration-color: var(--black090);
    transition: text-decoration-color 130ms;
}