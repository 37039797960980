.container {
    position: relative;
    border-radius: 1000000px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--lightGrey);
    box-shadow: 0px 4px 12px rgba(0,0,0,0.15);
}
.container.sized {
    padding-top: 100%;
    height: initial;
    width: 100%;
    min-width: 100%;
}

.iconContainer {
    display: flex;
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
}

.icon { 
    position: absolute;
    margin: auto;
    z-index: 1;
}

.cover {
    height: 100%;
    width: 100%;
    border-radius: 1000000px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    object-fit: cover;
}

.border {
    box-shadow: var(--shadow-clickable);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    border-radius: 1000000px;
    z-index: 3;
    transition: box-shadow 200ms;
    overflow: hidden;
    text-indent: 110%;
}

:global(.light) .border, .border.noBorder {
    box-shadow: none;
}

.container.clickable:hover .border {
    box-shadow: var(--shadow-clickable-active);
}

.container.forcedClickable .border {
    box-shadow: var(--shadow-clickable-active);
}

.borderTitle {
    display: inline-block;
    white-space: nowrap;
    color: var(--highDarkGrey);
    width: 100%;
    font-weight: 600;
}
