.container {
    position: relative;
    cursor: pointer;
}

.bg {
    position: absolute;
}
.container.i32 .bg {
    height: 32px;
}
.container.i24 .bg {
    height: 24px;
}

.star {
    overflow: hidden;
    opacity: 0;
    z-index: 1;
}
.container.i32 .star {
    width: 16px; 
    height: 32px; 
}
.container.i24 .star {
    width: 12px; 
    height: 24px; 
}
.star.show {
    opacity: 1;
}

.container.i32 .starIcon {
    margin-left: -16px;
}
.container.i24 .starIcon {
    margin-left: -12px;
}
.starIcon.first {
    margin-left: 0 !important;
}