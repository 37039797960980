.results {
    margin-top: 4px;
    width: calc(100% - 18px);
    height: 320px;
    overflow-x: hidden;
    overflow-y: scroll;
}
:global(.light) .results {
    width: calc(100% - 16px);
}

.results .loadMore {
    font-weight: 500;
    border-radius: var(--border-radius-small);
    color: var(--black);
    cursor: pointer;
    padding: 12px 8px;
    background-color: transparent;
    transition: background-color 200ms;
}
.results .loadMore:hover, .results .loadMore.active {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.results .loadMore:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
:global(.light) .results .loadMore:hover,  :global(.light) .results .loadMore.active  {
    background-color: var(--lightestGrey);
}