.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.alignCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.title {
    font-size: 16px;
    color: var(--black);
    transition: color 250ms;
    text-align: center;
}

.subText {
    font-size: 14px;
    color: var(--highDarkGrey);
    text-align: center;
}

.textContainer:hover .title {
    color: var(--blue);
}