.container {
    position: relative;
}

.bg {
    position: absolute;
}
.container.i24 .bg {
    height: 24px;
}
.container.i20 .bg {
    height: 20px;
}
.container.i18 .bg {
    height: 18px;
}
.container.i16 .bg {
    height: 16px;
}
.container.i14 .bg {
    height: 14px;
}

.mg {
    margin-top: -1px;
}

.star {
    overflow: hidden;
    opacity: 0;
    z-index: 1;
}
.container.i24 .star {
    width: 12px; 
    height: 24px; 
}
.container.i20 .star {
    width: 10px; 
    height: 20px; 
}
.container.i18 .star {
    width: 9px; 
    height: 18px; 
}
.container.i16 .star {
    width: 8px; 
    height: 16px; 
}
.container.i14 .star {
    width: 7px; 
    height: 14px; 
}
.star.show {
    opacity: 1;
}

.container.i24 .starIcon {
    margin-left: -12px;
}
.container.i20 .starIcon {
    margin-left: -10px;
}
.container.i18 .starIcon {
    margin-left: -9px;
}
.container.i16 .starIcon {
    margin-left: -8px;
}
.container.i14 .starIcon {
    margin-left: -7px;
}
.starIcon.first {
    margin-left: 0 !important;
}