/* Secondary */
.iconContainer {
    width: 38px;
    height: 38px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--lightGrey);
    transition: background-color 150ms;

    box-shadow: var(--static-button-shadow-secondary);
}
.iconContainer:hover {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.iconContainer:active {
    background-color: var(--lightGrey3);
    transition: background-color 0ms;
}

/* Secondary Two */
.iconContainer.secondaryTwo {
    background-color: var(--lightGrey2);
}
.iconContainer.secondaryTwo:hover {
    background-color: var(--lightGrey3);
}
.iconContainer.secondaryTwo:active {
    background-color: var(--grey040);
}

/* Transparent */
.iconContainer.transparent {
    background-color: transparent;
    box-shadow: none;
}
.iconContainer.transparent:hover {
    background-color: var(--lightGrey);
}
.iconContainer.transparent:active {
    background-color: var(--lightGrey2);
}

/* Transparent Bright */
.iconContainer.transparentBright {
    background-color: transparent;
    box-shadow: none;
}
.iconContainer.transparentBright:hover {
    background-color: var(--lightGrey2);
}
.iconContainer.transparentBright:active {
    background-color: var(--lightGrey3);
}

/* Transparent Super Bright */
.iconContainer.transparentSuperBright {
    background-color: transparent;
    box-shadow: none;
}
.iconContainer.transparentSuperBright:hover {
    background-color: var(--lightGrey3);
}
.iconContainer.transparentSuperBright:active {
    background-color: var(--grey040);
}

/* Primary */
.iconContainer.primary {
    background-color: var(--blue);
    box-shadow: var(--static-button-shadow);
}
.iconContainer.primary:hover {
    background-color: var(--blue090);
}
.iconContainer.primary:active {
    background-color: var(--blue075);
}
.iconContainer.primary .icon {
    color: var(--constantWhite);
}

/* Red */
.iconContainer.destructive {
    background-color: var(--red);
    box-shadow: var(--static-button-shadow);
}
.iconContainer.destructive:hover {
    background-color: var(--red090);
}
.iconContainer.destructive:active {
    background-color: var(--red075);
}
.iconContainer.destructive .icon {
    color: var(--constantWhite);
}

/* Red Light */
.iconContainer.destructiveTransparent {
    background-color: transparent;
    box-shadow: none;
}
.iconContainer.destructiveTransparent:hover {
    background-color: var(--lightGrey2);
}
.iconContainer.destructiveTransparent:active {
    background-color: var(--lightGrey3);
}
.iconContainer.destructiveTransparent .icon {
    color: var(--red);
}

/* Red Super Light */
.iconContainer.destructiveTransparentSuperBright {
    background-color: transparent;
    box-shadow: none;
}
.iconContainer.destructiveTransparentSuperBright:hover {
    background-color: var(--lightGrey3);
}
.iconContainer.destructiveTransparentSuperBright:active {
    background-color: var(--grey040);
}
.iconContainer.destructiveTransparentSuperBright .icon {
    color: var(--red);
}

/* Yellow */
.iconContainer.yellow {
    background-color: var(--yellow);
    box-shadow: var(--static-button-shadow);
}
.iconContainer.yellow:hover {
    background-color: var(--yellow090);
}
.iconContainer.yellow:active {
    background-color: var(--yellow075);
}
.iconContainer.yellow .icon {
    color: var(--constantWhite);
}
