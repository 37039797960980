/* MOBLIE */
.mobile {
    background-color: var(--highDarkGrey) !important;
    transition: background-color 200ms;
}

.mobile:hover {
    background-color: var(--black) !important;
}

/* HOME TYPE */
.homeType {
    color: var(--highDarkGrey);
    transition: color 200ms;
}

.homeType:hover {
    color: var(--blue);
}
.homeType.active {
    color: var(--blue);
    background: -webkit-linear-gradient(45deg, #1592F7, #3ECEFB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.homeType.active.onMobile {
    color: var(--blue);
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
}

/* VIDEO */
.videoWrapper {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    width: 100%;
}

.videoContainer {
    position: absolute;
    width: 960px;
    height: 540px;
}
.video {
    background: linear-gradient(45deg, #1592F7, #3ECEFB);
    cursor: pointer;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius-large);
    box-shadow: 0px 8px 48px rgba(0,0,0,0.15);
}

.videoWrapper .videoControllerWrapper {
    cursor: pointer;
    width: calc(100vw / 2.1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    max-width: 960px;
}

.videoWrapper .videoController {
    cursor: pointer;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 1000px;
    background-color: rgba(21,32,44,0.60);
    transform: scale(1.0);
    transition: background-color 200ms, transform 200ms;
}
.videoWrapper:hover .videoController {
    background-color: rgba(21,32,44,0.85);
    transform: scale(1.1);
}

.gradientText {
    background: -webkit-linear-gradient(45deg, #1592F7, #3ECEFB);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.radialGradient {
    background: radial-gradient(
    rgba(62, 251, 227, 0.100) 0% , 
    rgba(62, 251, 227, 0.050) 25%,
    rgba(62, 251, 227, 0.040) 35%,
    rgba(62, 251, 227, 0.015) 55%,
    rgba(62, 251, 227, 0.010) 60%,
    rgba(62, 251, 227, 0.000) 70%
    );
    width: 400px;
    height: 250px;
    position: absolute;
    opacity: 0.8;
    pointer-events: none;
}

/* HEADER BG */
.headerBackgroundOld {
    width: 100%;
    height: 770px;
    background: var(--white);
    position: absolute;
    top: -385px;
    left: 0;
    z-index: -1;
    transform: skewY(-8deg);
    transform-origin: top center;
}

.headerBackground {
    width: 100%;
    height: 657px;
    background: linear-gradient(0deg, rgba(11, 103, 250, 0.0), rgba(62, 205, 251, 0.1));
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
}
.headerLeft {
    width: 400px;
    height: 580px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../assets/images/home-albums-left-2-min.webp);
}
.headerRight {
    width: 400px;
    height: 580px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../assets/images/home-albums-right-min.webp);
}

/* MEDIA QUERIES OLD FOR VIDEO */
@media(max-width: 1230px) {
    .videoContainer {
        width: 907px;
        height: 510px;
    }
}

@media(max-width: 1180px) {
    .videoContainer {
        width: 853px;
        height: 480px;
    }
}

@media(max-width: 1100px) {
    .videoWrapper {
        justify-content: center;
    }

    .videoContainer {
        position: initial;
        width: initial;
        height: initial;
        background-color: transparent;
    }

    .video {
        max-width: 960px;
        border-radius: var(--border-radius-large);
    }

    .videoControllerWrapper {
        width: 100%;
    }
}

@media(max-width: 960px) {
    .video {
        max-width: 960px;
        border-radius: var(--border-radius-mid);
    }
}

/* MEDIA QUERIES */
@media(max-width: 1280px) {
    .headerLeft {
        left: -32px;
    }
    .headerRight {
        right: -32px;
    }
}

@media(max-width: 1230px) {
    .headerLeft {
        width: 400px;
        height: 540px;
        left: -32px;
    }
    .headerRight {
        width: 400px;
        height: 540px;
        right: -32px;
    }
}

@media(max-width: 1180px) {
    .headerLeft {
        width: 400px;
        height: 540px;
        left: -64px;
    }
    .headerRight {
        width: 400px;
        height: 540px;
        right: -64px;
    }
}

@media(max-width: 1100px) {
    .headerLeft {
        width: 400px;
        height: 540px;
        left: -128px;
        top: 48px;
    }
    .headerRight {
        width: 400px;
        height: 540px;
        right: -128px;
        top: 48px;
    }

    .radialGradient {
        width: 400px;
        height: 200px;
    }

}

@media(max-width: 960px) {
    .headerLeft {
        display: none;
    }
    .headerRight {
        display: none;
    }
}

/* PRO AD */
.proad {
    box-shadow: 0px 1px 2px rgba(0,0,0,0.08);
    box-shadow: 0px 4px 16px rgba(0,0,0,0.05);
}

/* HOVERS */
.discover {
    transition: background-color 200ms;
}
.discover:hover {
    background-color: var(--hoverColor);
    transition: background-color 0ms;
}
.a {
    transition: color 200ms;
}
.a:hover {
    color: var(--blue);
}

.switch {
    transition: color 200ms;
}
.switch:hover {
    color: var(--black);
    transition: color 0ms;
}
.switchActive {
    color: var(--blue) !important;
}


.moreBtn {
    padding: 12px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
    background-color: transparent;
    transition: background-color 150ms;
}
.moreBtn:hover {
    background-color: var(--hoverColor);
    transition: background-color 0ms;
}
.moreBtn:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}

.betaImage {
    height: 150px;
    background-image: url(../../assets/images/musicboard-beta-logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: var(--border-radius-mid);
    cursor: pointer;
}
:global(.light) .betaImage {
    background-image: url(../../assets/images/musicboard-beta-logo-black.svg);
}



.clickableP > i, .clickableP > p {
    color: var(--black);
    transition: color 200ms;
}
.clickableP:hover > i, .clickableP:hover > p {
    color: var(--blue);
}

.underline {
    text-decoration: underline;
    text-decoration-color: var(--highDarkGrey060);
}

.badgeWrapper {
    overflow: hidden;
    position: relative;
}

.badgeTitle {
    display: inline-block;
    white-space: nowrap;
    color: var(--highDarkGrey);
    font-weight: 600;
    position: absolute;
    top: 0;
    right: 0;
    text-indent: 110%;
}
