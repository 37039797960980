.container .content {
    border-radius: 20px;
    background-color: var(--lightGrey);
    padding: 12px 16px;
    display: inline-flex;
    flex-direction: column;
}
.container .content .username {
    color: var(--black);
    transition: color 200ms;
    cursor: pointer;
}
.container .content .username:hover {
    color: var(--blue);
}

.container .content {
    border-radius: var(--border-radius-large);
    background-color: var(--lightGrey);
    padding: 12px 16px;
    display: inline-flex;
    flex-direction: column;
}

.container .favorite {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.container .favorite.notLoading:hover {
    color: var(--black);
    transition: color 0ms;
}
.container .favorite.active {
    color: var(--red) !important;
}

.container .clickable {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.container .clickable:hover {
    color: var(--black);
    transition: color 0ms;
}

.container .report {
    opacity: 0;
}
.container:hover .report {
    opacity: 1;
}

.hoverUnderline {

}
.hoverUnderline {
    color: var(--blue);
    text-decoration: underline;
    text-decoration-color: var(--blue050);
    transition: text-decoration-color 200ms;
}
.hoverUnderline:hover {
    text-decoration-color: var(--blue090);
    transition: text-decoration-color 130ms;
}