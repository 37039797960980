.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background-color: transparent;
    border-radius: var(--border-radius-small);
    transition: background-color 150ms;
}
.container:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.container:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.container.disabled {
    background-color: transparent;
    cursor: default !important;
}

.a.disabled * {
    color: var(--highDarkGrey) !important;
    cursor: default !important;
}

.text {
    transition: color 200ms;   
}
.subText {
    margin-left: 8px;
    font-weight: normal;
    color: var(--highDarkGrey);
    transition: color 200ms;
}