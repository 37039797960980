/* ABSOLUTE DESIGN */
.absoluteContainer {
    display: flex;
    position: absolute;
    bottom: -8px; 
    width: 24px; 
    height: 24px; 
    border-radius: 1000px; 
    background-color: var(--darkGrey); 
    align-items: center; 
    justify-content: center;
    z-index: 10;
    cursor: pointer;

    opacity: 0;
    transition: opacity 200ms, background-color 200ms;
    pointer-events: none;
}

:global(.content-options-wrapper):hover .absoluteContainer {
    opacity: 1;
    transition: opacity 0ms, background-color 200ms;
    pointer-events: initial;
}

.absoluteContainer:hover {
    background-color: var(--blue); 
    transition: opacity 200ms, background-color 0ms !important;
}

.absoluteIcon {
    color: var(--white);
}
.absoluteContainer:hover .absoluteIcon {
    color: var(--black);
}
:global(.light) .absoluteContainer:hover .absoluteIcon {
    color: var(--white);
}

/* NORMAL DESIGN */
.normalContainer {
    width: 24px; 
    height: 24px; 
    border-radius: 1000px; 
    display: flex;
    align-items: center; 
    justify-content: center;
    cursor: pointer;

    opacity: 0;
    transition: opacity 200ms;
    pointer-events: none;
}

:global(.content-options-wrapper):hover .normalContainer {
    opacity: 1;
    transition: opacity 0ms;
    pointer-events: initial;
}

.normalContainer .normalIcon {
    color: var(--grey);
    transition: color 200ms;
}
.normalContainer:hover .normalIcon {
    color: var(--black);
    transition: color 0ms;
}

/* TOOLTIP MENU */
.menuContainer {
    padding: 0 !important;
    cursor: default;
}

.menuItem {
    background-color: transparent;
    transition: background-color 150ms;
    padding: 12px;
    padding-right: 24px;
    border-radius: var(--border-radius-small);
}
.menuItem:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.menuItem:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
:global(.light) .menuItem:hover {
    background-color: var(--lightestGrey);
}

.menuItem .menuItemText {
    color: var(--black) !important;
}

.line {
    width: 100%;
    height: 1px;
    background-color: var(--lightGrey);
}