/* ICON */
.icon {
    color: var(--highDarkGrey);
    transition: color 200ms;
    cursor: pointer;
    transform: rotateY(180deg);
    padding: 6px;
    margin: -6px;
}
:global(.light) .icon {
    color: var(--grey);
}
.icon:hover {
    color: var(--black);
    transition: color 0ms;
}

/* ITEM */
.item {
    background-color: transparent;
    transition: background-color 150ms;
    padding: 12px;
    padding-right: 24px;
    border-radius: var(--border-radius-small);
    white-space: nowrap;
}
.item:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.item:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
:global(.light) .item:hover {
    background-color: var(--lightestGrey);
}

.item i, .item h6 {
    transition: color 200ms;
}
.item.copied:hover i, .item.copied:hover h6 {
    color: var(--green) !important;
    transition: color 0ms;
}

/* LINK */
.link {
    position: absolute;
    left: -5000px;
    top: -5000px;
}

.disabledShare {
    opacity: 0.5;
    pointer-events: none;
}