/* Menu & BG */
@keyframes mobilenavigationmenuOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.wrapper {
    position: fixed;
    top: 57px;
    left: 0;
    width: 100%;
    height: calc(100vh - 57px);

    background-color: rgba(0,0,0,0.5);

    display: flex;
    justify-content: flex-start;

    animation: mobilenavigationmenuOpacity 200ms ease-out 0ms 1 forwards;
    z-index: 2130;
}

.background {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Menu */
@keyframes mobilenavigationmenuTransform {
    0% {
        transform: translateX(-400px);
    }
    100% {
        transform: translateX(0px);
    }
}

.menu {
    border-right: 1px solid var(--lightGrey);
    background-color: var(--white);
    width: 100%;
    max-width: 500px;
    height: 100%;

    animation: mobilenavigationmenuTransform 200ms ease-out 0ms 1 forwards;

    display: flex;
    flex-direction: column;
}


.menuScroller {
    flex: 1 1;
    overflow-y: scroll;
    padding-bottom: 120px;
}

/* Item list */
.menuItemWrapper .menuItemList {
    background-color: var(--white);
    max-height: 0px;
    overflow: hidden;
    transition: max-height 200ms;
}

.menuItemWrapper.active .menuItemList {
    max-height: 448px;
}

/* Item arrow */
.menuItemWrapper .menuItemArrow {
    transform: rotateZ(0deg);
    transition: transform 200ms;
}

.menuItemWrapper.active .menuItemArrow {
    transform: rotateZ(180deg);
}

/* Socials */
.menuSocialWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
}

.menuSocialA {
    padding: 16px;
}

.menuSocialA .menuSocial {
    color: var(--black);
    transition: background-color 200ms;
}

.menuSocialA .menuSocial.twitter {
    mask-image: url(../../../../assets/icons/x.svg);
    width: 28px;
    height: 28px;
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: var(--black);
}
.menuSocialA .menuSocial.instagram {
    mask-image: url(../../../../assets/images/instagram.svg);
    width: 24px;
    height: 24px;
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: var(--black);
}


.menuSocialA:hover .menuSocial.twitter {
    background-color: var(--twitter);
    transition: background-color 100ms;
}
.menuSocialA:hover .menuSocial.instagram {
    background-color: var(--instagram);
    transition: background-color 100ms;
}



/* Light mode fix */
.light-mode .menuItemWrapper .menuItemList {
    background-color: var(--lightestGrey);
    box-shadow: 0px 1px 0px var(--lightGrey) inset, 
                0px -1px 0px var(--lightGrey) inset;
}

.itemContainer {
    padding: 24px;
}
.innerItemContainer {
    height: 20px;
    padding: 16px 24px;
}

.itemContainer, .innerItemContainer {
    cursor: pointer;
    background-color: transparent;
    transition: background-color 150ms;
}
.itemContainer:hover, .innerItemContainer:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.itemContainer:active, .innerItemContainer:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}


/* Mobile fix */
@media(max-width: 490px) {
    .wrapper .itemContainer {
        background-color: transparent !important;
    }   
    .wrapper .innerItemContainer {
        background-color: transparent !important;
    }   
}

.listItemIcon {
    width: 25px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}
.listItemIcon.crown {
    background-image: url(../../../../assets/images/crown.svg);
}
.listItemIcon.fire {
    background-image: url(../../../../assets/images/fire.svg);
}
.listItemIcon.trend {
    background-image: url(../../../../assets/images/trend.svg);
}

.badgeWrapper {
    overflow: hidden;
    position: relative;
}

.badgeTitle {
    display: inline-block;
    white-space: nowrap;
    color: var(--highDarkGrey);
    font-weight: 600;
    position: absolute;
    top: 0;
    right: 0;
    text-indent: 110%;
}
