.dot {
    width: 9px;
    height: 9px;
    border-radius: 1000px;
    background-color: var(--red);
    position: absolute;
    top: 0;
    right: 0;
}

.scroll {
    max-height: 80vh;
    overflow-y: scroll;
    padding: 16px 8px;
}

.container {
    width: 450px;
    padding: 0px !important;
}

.fixedNav {
    position: fixed;
    background-color: var(--white);
    box-shadow: var(--shadow-light);
    padding: 0px 16px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 32px);
    z-index: 900;
    border-bottom: 1px solid var(--lightGrey);
    top: 0;
}
:global(.light) .fixedNav {
    border-bottom: none;
}

@media(max-width: 630px) {
    .container {
        position: fixed;
        top: 0 !important;
        left: 0;
        width: 100%;
        z-index: 10000000;
        border-radius: 0px;
        height: 100%;
    }
    .scroll {
        max-height: 100vh;
    }
}