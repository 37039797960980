.searchPreview {
	height: 40px;
	border-radius: 1000px;
	background-color: var(--lightestGrey);
	padding-left: 16px;
	padding-right: 64px;
	cursor: text;
}

.line {
	height: 1px;
	width: 100%;
	background-color: var(--lightGrey);
}