.tracklistOverlay {
    position: absolute;
    bottom: 0;
    height: 96px;
    background: linear-gradient(var(--whiteTransparent), var(--white));
    width: calc(100% - 32px);
    border-bottom: 1px solid var(--white);
    display: none;
    pointer-events: none;
}
.tracklistOverlay.active {
    pointer-events: initial;
    cursor: pointer;
    opacity: 1;
    display: initial;
}

.diskNumber {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: var(--grey);
    padding: 12px 0px;
}
.diskNumberIcon {
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.streamingServiceIcon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: block;
    object-fit: contain;
    object-position: center;
}