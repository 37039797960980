.viewIcon {
    color: var(--grey);
    transition: color 200ms;
}
.viewIcon:hover {
    color: var(--black);
}
.viewIcon.active {
    color: var(--black);
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}
.overlay.active {
    pointer-events: initial;
    background-color: var(--white060);
}