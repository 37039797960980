/* CONTAINER */
.container {
    padding: 16px;
    border-bottom: 1px solid var(--lightGrey);
    background-color: transparent;
    transition: background-color 150ms;
}
.container.lighterStyle {
    border-bottom: 1px solid var(--darkLightGrey);
}
:global(.light) .container.lighterStyle {
    border-bottom: 1px solid var(--lightGrey);
}

.container:hover, .container.active {
    background-color: var(--hoverColor);
    transition: background-color 0ms;
}
.container:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.container.lighterStyle:hover, .container.lighterStyle.active {
    background-color: var(--darkLightGrey);
}
:global(.light) .container.lighterStyle:hover, :global(.light) .container.lighterStyle.active {
    background-color: var(--lightGrey);
}

.container.hideBorder {
    border-bottom: none;
}

.container.last {
    border-bottom: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.container.first {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

/* HOVERS */
.a {
    color: var(--black);
    transition: color 200ms;
}
.a:hover {
    color: var(--blue);
}

.creator {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.creator:hover {
    color: var(--black);
}

/* ICON AND COUNTS */
.container .icon {
    color: var(--highDarkGrey);
    transition: color 200ms;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: -6px;
    margin-bottom: -6px;
}
:global(.light) .container .icon {
    color: var(--grey);
}
:global(.light) .container .icon.counts {
    color: var(--highDarkGrey);
}
.container .icon:hover {
    color: var(--black);
    transition: color 0ms;
}
:global(.light) .container .icon.counts:hover {
    color: var(--black);
    transition: color 0ms;
}
.container .icon.active {
    color: var(--red);
}


.coverContainer {
    position: relative;
}

.coverContainer .coverBorder {
    box-shadow: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    border-radius: var(--border-radius-small);
    z-index: 300000;
    transition: box-shadow 250ms;
}

.coverContainer:hover .coverBorder {
    box-shadow: 0px 3px 0px var(--blue) inset, 
                0px -3px 0px var(--blue) inset, 
                3px 0px 0px var(--blue) inset, 
                -3px 0px 0px var(--blue) inset;
}


.featuredBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    margin-bottom: -1px;
}
.featuredBox > div {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: var(--border-radius-small);
    background-color: rgba(255, 149, 0, 0.15); 
}
.featuredBox > div > h6 {
    font-size: 12px; 
    color: rgba(255, 149, 0, 1);
}
