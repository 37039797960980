@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.fixed {
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 100000;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.97);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000000000;
	animation: fade-in 200ms ease-out;
}

.bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

@keyframes move-in {
    0% {
        transform: translateY(30px);
    }
    100% {
        transform: translateY(0px);
    }
}

.video {
    max-width: 960px;
    width: 100%;
    max-height: calc(960px / 1.7777);
    height: calc(100vw / 1.7777);
    border-radius: var(--border-radius-mid);
    overflow: hidden;
    outline: none;
    z-index: 120;
	animation: move-in 300ms ease-out;
	z-index: 2;
}

@media(max-width: 960px) {
    .video {
        border-radius: 0px;
    }
}

.close {
    position: absolute;
    top: 16px;
    left: 16px;

    width: 40px;
    height: 40px;
    border-radius: 1000px;
    background-color: rgba(255,255,255,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 3;
    transition: background-color 200ms;
}
.close:hover {
    background-color: rgba(255,255,255,1);
}
.close .icon {
    color: white;
    transition: color 200ms;
}
.close:hover .icon {
    color: black;
}