.container {
    padding: 10px 16px;
    background-color: var(--lightGrey);
    border-radius: var(--border-radius-small);
    transition: background-color 150ms;
    position: relative;
    overflow: hidden;
}

.container.isSmall {
    padding: 8px 14px;
}

:global(.truncate-wrapper):hover .container {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
:global(.truncate-wrapper):active .container {
    background-color: var(--lightGrey3);
    transition: background-color 0ms;
}

.container:hover {
    background-color: var(--lightGrey3) !important;
    transition: background-color 0ms;
}
.container:active {
    background-color: var(--grey075) !important;
    transition: background-color 0ms;
}

.option {
    font-size: 16px; 
    line-height: 24px;
    color: var(--black); 
}
.container.isSmall .option {
    font-size: 14px; 
    line-height: 21px;
}

.container.active .option {
    font-weight: 600;
}

.answerCount {
    font-size: 16px; 
    line-height: 24px;
    color: var(--black); 
    margin-left: 8px;
    min-width: 12px;
    text-align: center;
}
.container.isSmall .answerCount {
    font-size: 14px; 
    line-height: 21px;
}

.answerCountBar {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.10);
    left: 0;
    height: 100%;
    transition: width 150ms;
}