.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.container h6 {
    color: var(--black);
    transition: color 200ms;
}
.container .textContent:hover h6 {
    color: var(--blue);
}

.verified {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 100;
}