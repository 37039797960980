.ratingContainer {
    position: relative;
}

.clearRatingBtn {
    position: absolute;
    left: -36px;
    transform: translateX(20px);
    opacity: 0;
    pointer-events: none;
    transition: transform 200ms, opacity 200ms;
    padding: 4px 8px;
    height: 20px;
    cursor: pointer;
}
.clearRatingBtn .clearRatingIcon {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.clearRatingBtn:hover .clearRatingIcon {
    color: var(--black);
    transition: color 100ms;
}
.ratingContainer:hover .clearRatingBtn.active {
    transform: translateX(0px);
    opacity: 1;
    pointer-events: all;
}