.container {
    
}

.container .item {
    opacity: 1;
    flex: 1 1;
    height: 66px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.container .textCnt {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.container .textCnt .number {
    position: absolute;
    font-size: 14px;
    color: var(--black);
    opacity: 0;
    cursor: default;
}

.rating {
    position: absolute;
}

.container:hover .item {
    opacity: 0.3;
}

.container:hover .item:hover {
    opacity: 1;
}

.container:hover .item:hover .textCnt .number {
    opacity: 1;
}
.container:hover .item:hover .textCnt .rating {
    opacity: 0;
}

.bar {
    width: calc(100% - 2px);
    border: 1px solid var(--white);
    background-color: var(--yellow);
    border-radius: 4px;
}

.ratingText {
    white-space: nowrap;
    text-align: center;
    color: var(--yellow);
    font-size: 13px;
    margin-right: 2px;
    margin-left: 1px;
}
