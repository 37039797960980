/* INPUT */
.container {
    position: relative;
}
.container.focused {
    width: 100%;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown {
    width: 100%;
    padding: 0 !important;
}

.inputContainer {
    flex: 1 1;
    height: 38px;
    border-radius: var(--border-radius-small);
    background-color: var(--lightestGrey);
    flex-direction: row;
    display: flex;
    align-items: center;
    position: relative;
    cursor: text;
    box-shadow: var(--shadow-input);
    transition: box-shadow 200ms;
    z-index: 2001;
    width: 319px;
}
.inputContainer:hover {
    box-shadow: var(--shadow-input-hover);
}
.inputContainer.active {
    box-shadow: var(--shadow-input-active);
}

@media (max-width: 1100px) {
    .inputContainer {
        width: 290px;
    }
}

.input {
    cursor: text;
    padding-left: 43px;
    padding-right: 24px;
    padding-bottom: 1px;
    border: none;
    outline: none;
    background: none;
    height: 37px;
    flex: 1 1;
    font-size: 14px;
    color: var(--black);
    width: 270px;
}
.input::placeholder {
    color: var(--highDarkGrey);
}

.icon {
    position: absolute;
    left: 14px;
    pointer-events: none;
}


.tabPickerItem {
    padding: 20px 0px;
    cursor: pointer;
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.tabPickerItem:hover, .tabPickerItem.active {
    color: var(--black);
    transition: color 100ms;
}

.tabPickerLine {
    box-shadow: border-box;
    width: calc(100% / 3 - 16px);
    margin: 0px 8px;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--blue);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    transition: left 200ms;
}

/* CONTENT */
.content {
    width: 100%;
    max-height: calc(60vh + 40px + 7px + 50px);
    min-height: 272px;
    overflow-y: scroll;
}
.content.mobile {
    flex-direction: column;
    align-items: initial;
}

.result {
    padding: 8px;
    border-radius: var(--border-radius-small);
    background-color: transparent;
    transition: 200ms;
    cursor: pointer;
}

.result:hover {
    background-color: var(--lightGrey);
    transition: 0ms;
}
.result.active {
    background-color: var(--lightGrey);
    transition: 0ms;
}
:global(.light) .result:hover, :global(.light) .result.active {
    background-color: var(--lightestGrey);
}

.result span {
    font-weight: 600;
}
.result .resultIcon {
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width: 960px) {
    .content {
        position: fixed !important;
        top: 56px !important;
        width: calc(100% - 16px);
        max-height: 100%;
        border: none !important;
        border-bottom: 1px solid var(--lightGrey) !important;
        border-radius: 0px !important;
        margin-right: 0px;
    }
    :global(.light) .content {
        border-bottom: none !important;
    }

    .nav {
        position: fixed;
        background-color: var(--white);
        box-shadow: var(--shadow-light);
        padding: 0px 16px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 32px);
        z-index: 2201;
        border-bottom: 1px solid var(--lightGrey);
        top: 0;
        left: 0;
    }
    :global(.light) .nav {
        border-bottom: none;
    }

    .input {
        width: initial;
    }
}

.searchMore {
    border-radius: var(--border-radius-small);
    cursor: pointer;
    padding: 12px 8px;
    background-color: transparent;
    transition: background-color 200ms;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.searchMore:hover, .searchMore.active {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.searchMore:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}

.searchMore p {
    font-weight: 500;
    color: var(--black) !important;
}
.searchMore.large p {
    font-size: 16px;
    font-weight: 600;
    color: var(--black) !important;
}

.searchMore .searchMoreIconContainer {
    width: 48px;
    height: 48px;
    border-radius: 100px;
    background-color: var(--lightGrey);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    transition: background-color 200ms;
}
:global(.light) .searchMore .searchMoreIconContainer {
    background-color: var(--lightestGrey);
}
.searchMore:hover .searchMoreIconContainer, .searchMore.active .searchMoreIconContainer {
    background-color: var(--darkLightGrey050);
    transition: background-color 0ms;
}

:global(.light) .searchMore:hover .searchMoreIconContainer,  :global(.light) .searchMore.active .searchMoreIconContainer {
    background-color: var(--lightGrey050);
}