.fixed {
	position: fixed;
	top: 16px;
	right: 16px;
	z-index: 100000;
}

@keyframes scale {
	0% {
		transform: scaleY(0);
	}
	100% {
		transform: scaleY(1);
	}
}
.lineContainer {
	transform-origin: 50% 0%;
	animation: scale 100ms ease-in-out;
	max-width: 600px;
	width: calc(100vw - 48px);
	border-radius: var(--border-radius-mid);
	overflow: hidden;
	box-shadow: var(--shadow-large-dark);
	position: relative;
}

.alertContainer {
	padding: 16px;
}

.a {
	color: white;
	text-decoration: underline;
	text-decoration-color: rgba(255,255,255, 0.5);
	transition: text-decoration-color 200ms;
}
.a:hover {
	text-decoration-color: rgba(255,255,255, 0.9);
	transition: text-decoration-color 130ms;
}

@keyframes line {
	0% {
		width: 100%;
	}
	100% {
		width: 0%;
	}
}
.timer {
	position: absolute;
	bottom: 0;
	width: 100%;
	animation-name: line;
	animation-duration: 4000ms;
	animation-timing-function: linear;
	height: 4px;
	background-color: rgba(255,255,255, 0.25);
}