.wrapper {
    padding: 64px;
    position: relative;
}

.title {
    font-size: 40px;
    line-height: 54px;
}

.closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
}

@media (max-width: 630px) {
    .wrapper {
        padding: 32px;
    }

    .closeButton {
        position: absolute;
        top: 0px;
        right: 0px;
    }
}

@media (max-width: 490px) {
    .wrapper {
        padding: 8px;
    }
}

@media (max-width: 768px) {
    .title {
        font-size: 30px;
        line-height: 40px;
    }
}

.forgot {
    color: var(--black);
    font-weight: 500;
    transition: text-decoration-color 200ms;
    text-decoration: underline;
    text-decoration-color: var(--black050);
}
.forgot:hover {
    text-decoration-color: var(--black090);
    transition: text-decoration-color 130ms;
}

@keyframes scaleY {
    0% {
        height: 0px;
    }
    100% {
        height: 20px;
    }
}
.errorWrapper {
    animation: scaleY 250ms ease-out 0ms 1 forwards;
}