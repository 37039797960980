/* CONTAINER */
.container {
    padding: 8px;
    border-radius: var(--border-radius-small);
    background-color: transparent;
    transition: background-color 150ms;
}
.container:hover {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.container:active {
    background-color: var(--lightGrey3);
    transition: background-color 0ms;
}
:global(.light) .container:hover {
    background-color: var(--lightGrey050);
}

/* INFORMATION */
.title {
    font-size: 16px;
    color: var(--black);
}
.artist {
    font-size: 14px;
    color: var(--highDarkGrey);
}

.cover {
    height: 60px;
    width: 60px;
    min-height: 60px;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-small);
    background-color: var(--lightGrey2);
    transition: background-color 150ms;
}

.container:hover .cover {
    background-color: var(--lightGrey3);
    transition: background-color 0ms;
}
.container:active .cover {
    background-color: var(--grey036);
    transition: background-color 0ms;
}