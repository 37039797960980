.SignInBtn-wrapper {
    position: relative;
    display: flex;
    align-items: center;

}

.SignInBtn-icon {
    position: absolute;
    left: 20px;
}