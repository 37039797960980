.a {
    color: var(--black);
    transition: color 200ms;
}
.a:hover {
    color: var(--blue);
}

.wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.disabledShare {
    pointer-events: none;
    opacity: 0.5;
}

.translateBtn {
    cursor: pointer;
    transition: color 200ms;
    color: var(--blue);
}
.translateBtn:hover {
    color: var(--blue_050);
}
