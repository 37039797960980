.container {
    background-color: var(--white);
    border-radius: var(--border-radius-mid);
    box-shadow: var(--shadow-light);
    overflow: hidden;
}

.container .item {
    padding: 16px;
    border-bottom: 1px solid var(--lightGrey);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: background-color 150ms;
}

.container .item:last-child {
    border-bottom: none;
}

.container .item:hover {
    background-color: var(--hoverColor);
    transition: background-color 0ms;
}
.container .item:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}

.item .text {
    text-align: center;
    color: var(--black);
    transition: color 200ms;
}

/*
.item:hover .text.notHoveringSpan {
    color: var(--blue);
    transition: color 0ms;
}

.activeSpan {
    transition: color 200ms;
    color: var(--highDarkGrey) !important;
}
.activeSpan:hover {
    color: var(--blue) !important;
    transition: color 0ms;
}

.icon {
    transition: color 200ms;
}
.item:hover .icon.notHoveringSpan {
    color: var(--blue) !important;
    transition: color 0ms;
}
*/