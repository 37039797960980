.myRatingContainer {
    position: relative;
}

.myRating {
    height: 51px;
    background-color: var(--lightGrey);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius-small);
}

:global(.light) .myRating {
    background-color: var(--lightestGrey);
}

.myRatingOverlayBtn {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
}

.myRatingContainer:hover .myRatingOverlayBtn {
    opacity: 0;
}


@keyframes spring-left {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-2px);
    }
    100% {
        transform: translateX(0px);
    }
}
.backButton {
    position: relative;
    height: 32px;
    padding: 0px 16px;
    background-color: var(--lightGrey);
    display: inline-flex;
    border-top-right-radius: var(--border-radius-small);
    border-bottom-right-radius: var(--border-radius-small);
    align-items: center;
    justify-content: center;
    right: -0px;
    transition: background-color 200ms;
}
.backButton:hover {
    background-color: var(--lightGrey2);
    animation: spring-left 300ms cubic-bezier(.5,0,.51,.99);
    transition: background-color 0ms;
}
.backButton:active {
    background-color: var(--lightGrey3);
    transition: background-color 0ms;
}
.backButton::before {
    content: '';
    position: absolute;
    left: -12px;
    width: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-right: 12px solid var(--lightGrey);
    transition: border-right 200ms;
}
.backButton:hover::before {
    border-right: 12px solid var(--lightGrey2);
    transition: border-right 0ms;
}
.backButton:active::before {
    border-right: 12px solid var(--lightGrey3);
    transition: border-right 0ms;
}

.backButtonText {
    color: var(--black);
}

@media(max-width: 1100px) {
    .backButton {
        margin-left: 12px;
    }
}

@keyframes fadeInLoadingInformation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.loadingInformation {
    color: var(--grey);
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    position: absolute;
    width: 100%;
    max-width: 490px;
    transform: translateY(104px);
    animation: fadeInLoadingInformation 130ms 1 forwards;
}
