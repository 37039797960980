.discover {
    transition: background-color 150ms;
}
.discover:hover {
    background-color: var(--hoverColor);
    transition: background-color 0ms;
}
.discover:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.a {
    transition: color 200ms;
}
.a:hover {
    color: var(--blue);
}

.continue {
    color: var(--blue);
    text-decoration: underline;
    text-decoration-color: var(--blue050);
    transition: text-decoration-color 130ms;
}
.continue:hover {
    text-decoration-color: var(--blue090);
}


.artistLink {
    color: var(--highDarkGrey);
}
.artistLink:hover {
    text-decoration: underline;
}

.featuredBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.featuredBox > div {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: var(--border-radius-small);
    background-color: var(--lightGrey);
}
.featuredBox > div > h6 {
    font-size: 12px;
    color: var(--black);
}

.featuredBox.red > div {
    background-color: rgba(255, 59, 48, 0.15);
}
.featuredBox.red > div > h6 {
    color: rgba(255, 59, 48, 1);
}

.featuredBox.orange > div {
    background-color: rgba(255, 149, 0, 0.15);
}
.featuredBox.orange > div > h6 {
    color: rgba(255, 149, 0, 1);
}

.featuredBox.yellow > div {
    background-color: rgba(255, 204, 0, 0.15);
}
.featuredBox.yellow > div > h6 {
    color: rgba(255, 204, 0, 1);
}

.featuredBox.green > div {
    background-color: rgba(52, 199, 89, 0.15);
}
.featuredBox.green > div > h6 {
    color: rgba(52, 199, 89, 1);
}

.featuredBox.blue > div {
    background-color: rgba(0, 122, 255, 0.15);
}
.featuredBox.blue > div > h6 {
    color: rgba(0, 122, 255, 1);
}

.featuredBox.purple > div {
    background-color: rgba(175, 82, 222, 0.15);
}
.featuredBox.purple > div > h6 {
    color: rgba(175, 82, 222, 1);
}

.featuredBox.pink > div {
    background-color: rgba(255, 45, 85, 0.15);
}
.featuredBox.pink > div > h6 {
    color: rgba(255, 45, 85, 1);
}

.featuredBox.gray > div {
    background-color: var(--lightGrey);
}
.featuredBox.gray > div > h6 {
    color: var(--black);
}

.multiple {
    width: 24px;
    height: 24px;
    padding: 8px;
    margin: -8px;
    margin-left: 4px;
    transform: rotate(90deg);
    transition: transform 150ms;
}
.multiple.open {
    transform: rotate(270deg);
}

.multiple * {
    transition: color 150ms;
    color: var(--highDarkGrey) !important;
}

.multiple:hover * {
    color: var(--blue) !important;
    transition: color 0ms;
}

.description {
    overflow: hidden;
    max-height: 0px;
    transition: max-height 200ms;
}
.description.open {
    overflow: visible;
    max-height: 600px;
}