.txt.pro {
    padding: 1px 8px;
    border-radius: var(--border-radius-small);
    background-color: var(--yellow);
}
.txt.text {
    color: white;
    font-size: 13px;
    white-space: nowrap;
}

.txt.contributorOne {
    background-color: var(--bronze);
}

.txt.contributorTwo {
    background-color: var(--silver);
}

.txt.contributorThree {
    background-color: var(--gold);
}

.txt.contributorFour {
    background-color: var(--diamond);
}

.txt.moderator {
    background-color: var(--green);
}

.img {
    width: 33px;
    height: 16px;
    background-image: url(../../assets/images/badges/pro-badge-blue-min.jpg);
    background-position: center center;
    background-size: cover;
    border-radius: 4px;
    box-shadow: 0 0 0 0.5px rgba(255,255,255,0.15) inset,
                0 1px 2px 0px rgba(0,0,0,0.3);
}

.img.contributorOne {
    background-image: url(../../assets/images/badges/mod-bronze-badge-min.jpg);
}

.img.contributorTwo {
    background-image: url(../../assets/images/badges/mod-silver-badge-min.jpg);
}

.img.contributorThree {
    background-image: url(../../assets/images/badges/mod-gold-badge-min.jpg);
}

.img.contributorFour {
    background-image: url(../../assets/images/badges/mod-diamond-badge-min.jpg);
}

.img.pro {
    background-image: url(../../assets/images/badges/pro-badge-blue-min.jpg);
}

:global(.light) .img.pro {
    box-shadow: 0 0 0 0.5px rgba(0,0,0,0.3) inset,
                0 1px 2px 0px rgba(0,0,0,0.3);
}

.img.large {
    width: 48px;
    height: 23px;
    border-radius: 6px;
}

.img.moderator {
    background-image: url(../../assets/images/badges/crew-badge-min.jpg);
}
