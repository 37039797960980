/* CONTAINERS */
.outerContainer {
	display: flex;
	width: 100%;
	justify-content: center;
	position: relative;
}

.container {
	position: absolute;
	width: 1200px;
	overflow: hidden;
	max-width: 100%;
	z-index: -10;
}

/* IMAGE */
.image {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: 35% center;
	transition: opacity 200ms;
	opacity: 0;
}
.image.blur {
	image-rendering: pixelated;
}
.image.visible {
	opacity: 1;
}

/* TOP LINE */ 
.topLine {
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: rgba(255, 255, 255, 0.4);
}

:global(.light) .topLine {
	background-color: rgba(0, 0, 0, 0.2);
}

/*  GRADIENT OVERLAY */
.overlay::before {
	content: "";
	display: block;
	position: absolute;
	z-index: 0;
	pointer-events: none;
	width: 1200px;
	max-width: 100%;
	top: 0;
	height: inherit;
	background-repeat: no-repeat;
	background-image: var(--full-background-dark-sides);
}

@media(max-width: 1200px) {
	.overlay::before {
		background-image: var(--full-background-dark-no-sides);
	}
}

.overlay.noSides::before {
	background-image: var(--full-background-dark-no-sides);
}

/* WHITE GRADIENT */
.overlay.whiteGradient::before {
	background-image: var(--full-background-light-sides);
}

@media(max-width: 1200px) {
	.overlay.whiteGradient::before {
		background-image: var(--full-background-light-no-sides);
	}
}

.overlay.whiteGradient.noSides::before {
	background-image: var(--full-background-light-no-sides);
}