/* HEADER */
.header {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	background-color: var(--lightGrey);
	box-shadow: none;
    border-bottom: 1px solid var(--darkLightGrey);
}
:global(.light) .header {
	background-color: var(--white);
	box-shadow: var(--shadow-light);
    border-bottom: none;
}

.overlay {
    position: absolute;
    width: 100%;
    height: calc(100% - 1px);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 200ms;
    background-color: var(--lightGrey070);
    z-index: 1000000;
    border-radius: var(--border-radius-mid);
}
:global(.light) .overlay {
    background-color: var(--lightestGrey060);
}
.overlay:hover {
    opacity: 1;
}