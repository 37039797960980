.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.container h6 {
    color: var(--black);
    transition: color 200ms;
}
.container:hover h6 {
    color: var(--blue);
}