.results {
    position: absolute;
    width: 100%;
    top: 38px;
    left: 0;
    background: var(--white);
    z-index: 1000;
    height: 320px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: -1;
}

.results .loadMore {
    font-weight: 500;
    border-radius: var(--border-radius-small);
    color: var(--black);
    cursor: pointer;
    padding: 8px;
    background-color: transparent;
    transition: background-color 150ms;
}
.results .loadMore:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.results .loadMore:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
