.container {
    background-color: var(--white);
    border-radius: var(--border-radius-mid);
    position: relative;
    box-shadow: var(--shadow-light);
    display: flex;
    align-items: flex-end;
    overflow: hidden;
}
:global(.light) .container {
    background-color: var(--white);
}

.content {
    padding: 32px;
    align-self: center;
    display: flex;
    flex-direction: column;
}

.image {
    object-fit: cover;
    object-position: bottom bottom;
    width: 573px;
    height: 286px;
    margin-right: 24px;
    margin-left: 64px;
}

.container .border {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}
:global(.light) .container .border {
    box-shadow: none;
}

.content p {
    font-size: 18px;
    line-height: 28px;
}

@media(max-width: 490px) {
    .content p {
        line-height: 24px;
        font-size: 16px;
    }
}

@media(max-width: 1100px) {
    .container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .image {
        object-fit: cover;
        object-position: center center;
        margin-left: 0px;
        margin-right: 0px;
        
    }

    .content {
        align-items: center;
        margin: 0px 32px;
    }
}
@media(max-width: 768px) {
    .image {
        width: 458px;
        height: 228px;
    }
}
@media(max-width: 630px) {
    .image {
        display: none;
        width: 0;
        height: 0;
    }
    .content {
        margin: 0;
    }
    .content {
        padding: 32px;
        padding-bottom: 40px;
    }
}