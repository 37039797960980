.icon {
    color: var(--highDarkGrey);
    transition: color 150ms;
}
:global(.light) .icon {
    color: var(--grey);
}
.icon:hover {
    color: var(--black);
    transition: color 0ms;
}
.icon:active {
    color: var(--black);
    opacity: 0.9;
    transition: color 0ms;
}

.lottie .icon {
    color: var(--highDarkGrey);
    transition: color 150ms;
}
:global(.light) .lottie .icon {
    color: var(--grey);
}
.lottie:hover .icon {
    color: var(--black);
    transition: color 0ms;
}

.lottie .overlay {
    position: absolute;
    border-radius: 50%;
    background-color: var(--highDarkGrey);
    transition: background-color 150ms;
}
:global(.light) .lottie .overlay {
    background-color: var(--grey);
}
.lottie:hover .overlay {
    background-color: var(--black);
    transition: background-color 0ms;
}

.lottie:active {
    opacity: 0.9;
}

.playingGif {
    display: block;
    position: absolute;
    height: 14px;
    width: 14px;
    transform: scale(0.8);
}
.playingGif.large {
    height: 18px;
    width: 18px;
    transform: scale(1.0);
}
