
.tooltip-container {
    background-color: var(--white) !important;
    transition: opacity 250ms !important;
    border-radius: var(--border-radius-mid) !important;
    opacity: 0;
    box-shadow: 0px 3px 12px rgba(0,0,0,0.08);
    color: var(--black) !important;
    z-index: 10000 !important;
    border: 1px solid var(--lightGrey) !important;
    padding-left: 18px !important;
    padding-right: 18px !important;
    padding-top: 7px !important;
    padding-bottom: 9px !important;
}

.tooltip-container span {
    font-size: 13px !important;
    line-height: 15px !important;
}

.tooltip-container.reverseColor {
    background-color: var(--black) !important;
    color: var(--white) !important;
    border: 1px solid var(--black) !important;
    font-weight: 500;
}

.light .tooltip-container {
    border: none !important;
}

.tooltip-container.max-width {
    max-width: 250px;
    text-align: center !important;
}

.tooltip-container::after {
    border-top-color: var(--lightGrey) !important;
    border-bottom-color: var(--lightGrey) !important;
    border: none !important;
}
.light .tooltip-container::after {
    border-top-color: var(--white) !important;
    border-bottom-color: var(--white) !important;
}

.tooltip-container.reverseColor::after {
    border-top-color: var(--black) !important;
    border-bottom-color: var(--black) !important;
    border: none !important;
}
.light .tooltip-container.reverseColor::after {
    border-top-color: var(--black) !important;
    border-bottom-color: var(--black) !important;
    border: none !important;
}

.tooltip-container.show {
    opacity: 1 !important;
}


.tooltip-container.react-tooltip__place-bottom  .react-tooltip-arrow {
    border-right: 1px solid var(--lightGrey) !important;
    border-bottom: 1px solid var(--lightGrey) !important;
    top: -5px !important;
}
.tooltip-container.react-tooltip__place-top  .react-tooltip-arrow {
    border-right: 1px solid var(--lightGrey) !important;
    border-bottom: 1px solid var(--lightGrey) !important;
    bottom: -5px !important;
}
.tooltip-container.react-tooltip__place-right  .react-tooltip-arrow {
    border-right: 1px solid var(--lightGrey) !important;
    border-bottom: 1px solid var(--lightGrey) !important;
    left: -5px !important;
}
.tooltip-container.react-tooltip__place-left  .react-tooltip-arrow {
    border-right: 1px solid var(--lightGrey) !important;
    border-bottom: 1px solid var(--lightGrey) !important;
    right: -5px !important;
}

.tooltip-container.react-tooltip__place-bottom.reverseColor  .react-tooltip-arrow {
    border-right: 1px solid var(--black) !important;
    border-bottom: 1px solid var(--black) !important;
}
.tooltip-container.react-tooltip__place-top.reverseColor  .react-tooltip-arrow {
    border-right: 1px solid var(--black) !important;
    border-bottom: 1px solid var(--black) !important;
}
.tooltip-container.react-tooltip__place-right.reverseColor  .react-tooltip-arrow {
    border-right: 1px solid var(--black) !important;
    border-bottom: 1px solid var(--black) !important;
}
.tooltip-container.react-tooltip__place-left.reverseColor  .react-tooltip-arrow {
    border-right: 1px solid var(--black) !important;
    border-bottom: 1px solid var(--black) !important;
}