.container {
    border-radius: var(--border-radius-small);
    background-color: transparent;
    transition: background-color 150ms;
    padding: 8px;
}
.container:hover, .container.active {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.container:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}


.artistLink {
    color: var(--highDarkGrey);
}
.artistLink:hover {
    text-decoration: underline;
}
