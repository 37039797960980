/* HEADER */
.header {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	background-color: var(--lightGrey);
	box-shadow: none;
    border-bottom: 1px solid var(--darkLightGrey);
}
:global(.light) .header {
	background-color: var(--white);
	box-shadow: var(--shadow-light);
    border-bottom: none;
}

.loadMore {
    font-weight: 500;
    border-radius: var(--border-radius-small);
    color: var(--black);
    cursor: pointer;
    padding: 8px;
    background-color: transparent;
    transition: background-color 150ms;
}
.loadMore:hover {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}
.loadMore:active {
    background-color: var(--lightGrey3);
    transition: background-color 0ms;
}
:global(.light) .loadMore:hover {
    background-color: var(--lightGrey050);
}