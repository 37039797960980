.container {
    padding: 0px !important;
    position: fixed;
    top: 0 !important;
    left: 0;
    width: 100%;
    z-index: 10000000;
    border-radius: 0px;
    height: 100%;
}

.scroll {
    max-height: 100vh;
    overflow-y: scroll;
    padding: 8px;
}

.fixedNav {
    position: fixed;
    background-color: var(--white);
    box-shadow: var(--shadow-light);
    padding: 0px 16px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 32px);
    z-index: 900;
    border-bottom: 1px solid var(--lightGrey);
    top: 0;
}
:global(.light) .fixedNav {
    border-bottom: none;
}

.item {
    padding: 8px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 200ms;
    min-height: 32px;
    border-radius: var(--border-radius-small);
}
.item:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
:global(.light) .item:hover {
    background-color: var(--lightestGrey);
}

.expand {
    height: 0px;
    overflow: hidden;
    transition: height 250ms ease-out;
}

.item .arrow {
    transform: rotate(0deg);
    transition: transform 200ms;
    height: 24px;
}
.item.active .arrow {
    transform: rotate(180deg);
}

.item .headingText, .item .headingIcon {
    color: var(--black);
    transition: color 150ms;
}
.item.active .headingText, .item.active .headingIcon {
    color: var(--blue);
}

.item .headingIconBg {
    background-color: var(--black);
    transition: background-color 150ms;
}
.item.active .headingIconBg {
    background-color: var(--blue);
}

.line {
    width: calc(100% - 16px);
    height: 1px;
    background-color: var(--lightGrey);
    margin-left: 8px;
}

.logo {
	background-image: url(../../../../assets/images/social-icon.png);
	background-position: left center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 38px;
	width: 38px;
	border-radius: 1000px;
	background-color: var(--deepBlue);
}


.icon {
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-left: 4px;
}
.crown {
    background-image: url(../../../../assets/images/crown.svg);
}
.fire {
    background-image: url(../../../../assets/images/fire.svg);
}
.trend {
    background-image: url(../../../../assets/images/trend.svg);
}

.badgeWrapper {
    overflow: hidden;
    position: relative;
}

.badgeTitle {
    display: inline-block;
    white-space: nowrap;
    color: var(--highDarkGrey);
    font-weight: 600;
    position: absolute;
    top: 0;
    right: 0;
    text-indent: 110%;
}
