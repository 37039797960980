.link {
    padding: 3px 12px 4px 8px;
    background: var(--lightestGrey);
    border-radius: var(--border-radius-mini);
    color: var(--black);
    cursor: pointer;
    margin: 0;
    outline: none;
    border: none;
    text-overflow: ellipsis;
}
:global(.light) .link {
    background: var(--lightGrey);
}

.tip {
    width: 68px;
    text-align: center;
}

.iconWrapper {
    height: 20px;
    display: flex;
    align-items: center;
}