
.react-date-picker__wrapper {
    border: none !important;
}

.react-date-picker__inputGroup {
    padding: 0 !important;
}

.react-date-picker__inputGroup__input {
    border: none !important;
    outline: none !important;
    height: 30px !important;
    font-size: 14px !important;
}

.react-date-picker__inputGroup__year {
    padding-left: 8px !important;
}
.react-date-picker__inputGroup__day {
    padding-right: 8px !important;
}


.react-date-picker__calendar {
    z-index: 4 !important;
}
.react-calendar {
    border-radius: 12px !important;
    border: none !important;
    margin-top: 4px !important;
    overflow: hidden !important;
}

.react-calendar__month-view__weekdays__weekday {
    color: black !important;
}

