.removeAds * {
    color: var(--grey) !important;
    transition: color 150ms;
    cursor: pointer;
}
.removeAds:hover * {
    color: var(--blue) !important;
    transition: color 0ms;
}
.removeAds:active * {
    color: var(--blue085) !important;
    transition: color 0ms;
}


.proAdFull {
    margin: auto;
    display: block;
    width: 100%;
    aspect-ratio: 7.768115942;
    object-fit: cover;
    object-position: center center;
    max-width: 1072px;
}
.proAdLarge {
    margin: auto;
    display: block;
    width: 100%;
    aspect-ratio: 5.1739130435;
    object-fit: cover;
    object-position: center center;
    max-width: 714px;
}
.proAdSmall {
    margin: auto;
    display: block;
    width: 100%;
    aspect-ratio: 1.3259259259;
    object-fit: cover;
    object-position: center center;
    max-width: 358px;
}
.proAdVertical {
    margin: auto;
    display: block;
    width: 100%;
    aspect-ratio: 0.4366666667;
    object-fit: cover;
    object-position: center center;
    max-width: 262px;
}

@media (max-height: 845px) {
    .proAdVertical {
        max-width: 240px;
    }
}
@media (max-height: 795px) {
    .proAdVertical {
        max-width: 220px;
    }
}
@media (max-height: 745px) {
    .proAdVertical {
        max-width: 200px;
    }
}

.proAdBottom {
    margin: auto;
    display: block;
    width: 420px;
    height: 54px;
    object-fit: cover;
    object-position: center center;
}

@media (max-width: 420px) {
    .proAdBottom {
        height: unset;
        width: 100%;
        aspect-ratio: 7.7777777778;
    }
}

.proAdLink {
    overflow: hidden;
    text-indent: 110%;
    position: relative;
}

.proAdTitle {
    display: inline-block;
    white-space: nowrap;
    color: var(--highDarkGrey);
    font-weight: 600;
    position: absolute;
    top: 0;
    right: 0;
}
