/* CONTAINER */
.container {
    padding: 8px;
    border-radius: var(--border-radius-small);
    background-color: transparent;
    transition: background-color 200ms;
}
.container:hover {
    background-color: var(--darkLightGrey050);
    transition: background-color 0ms;
}
:global(.light) .container:hover {
    background-color: var(--lightGrey050);
}

/* INFORMATION */
.title {
    font-size: 16px;
    color: var(--black);
    transition: color 250ms;
}

.artist {
    font-size: 14px;
    color: var(--highDarkGrey);
}


@media(max-width: 490px) {
    /*
    .title {
        font-size: 15px;
    }
    
    .artist {
        font-size: 13px;
    }
    */
}