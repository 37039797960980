.item {
    padding: 4px;
}
.itemText {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.item:hover .itemText {
    color: var(--black);
}
.item.active .itemText {
    color: var(--black);
}

.arrow {
    transform: rotate(0deg);
    transition: transform 150ms ease-out;
}
.arrow.active {
    transform: rotate(180deg);
}



.menuItem .menuItemText {
    color: var(--black);
    font-size: 14px;
    white-space: nowrap;
}
.menuItem:hover .menuItemText {
    color: var(--black);
}

.menuItemIconContainer {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    background-color: var(--lightGrey);
}

.menuItem .menuItemIcon {
    color: var(--black);
}

.menuItem {
    background-color: transparent;
    transition: background-color 150ms;
    padding: 12px;
    border-radius: var(--border-radius-small);
}
.menuItem:hover {
    background-color: var(--lightGrey);
    transition: background-color 0ms;
}
.menuItem:active {
    background-color: var(--lightGrey2);
    transition: background-color 0ms;
}