.checkBox {
	height: 26px;
	width: 26px;
	border-radius: var(--border-radius-small);
	display: flex;
	align-items: center;
    justify-content: center;
	background-color: var(--white);
    box-shadow: var(--shadow-clickable);
    transition: box-shadow 200ms, background-color 200ms;
}

.container {
    width: fit-content;
    cursor: not-allowed;
}
.container.allowed {
    cursor: pointer;
}

.checkBox.allowed:hover {
    box-shadow: var(--shadow-clickable-hover);
}
.checkBox.allowed:active {
    box-shadow: var(--shadow-clickable-active);
}


.checkBox.active {
    background-color: var(--green);
    box-shadow: var(--shadow-green);
}
.checkBox.allowed.active:hover {
    box-shadow: var(--shadow-green-hover);
}
.checkBox.allowed.active:active {
    box-shadow: var(--shadow-green-active);
}


.checkBox .checkMark {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 200ms, transform 200ms;
}
.checkBox.active .checkMark {
    opacity: 1;
    transform: scale(1);
}

.checkText {
    color: var(--highDarkGrey);
    transition: color 200ms;
}
.checkText.active {
    color: var(--black);
}